@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.app-sponsors {
    .carousel-style {
        display: flex;
        overflow: hidden;
    }
    .slider-class {
        display: flex;
        margin-bottom: 5px;
        list-style-type: none;
        padding-left: 0;
        .react-multi-carousel-item {
            > div {
                width: inherit;
                height: 100%;
                padding-right: 10px;
                .app-sponsors--item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    background-color: var(--light-clr);
                    border-radius: 3px;
                    box-shadow: 0 1px 9px var(--card-box-shadow-color);
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: inherit;
                        height: 100%;
                        img {
                            width: inherit !important;
                        }
                    }
                }
            }
        }
    }
}
