@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.attendee-item {
    @include media("atc-1") {
        max-width: 25% !important;
        flex: 25% !important;
    }
    &--inner {
        margin-bottom: 30px;
        &:hover {
            box-shadow: 0px 3px 9px var(--primary-80);
        }
        .avatar {
            .profile-avatar {
                position: relative;
                i {
                    position: relative;
                    display: block;
                    width: 156px;
                    height: 156px;
                    margin: auto;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    border-radius: var(--circle-border-radius);

                    &::after {
                        position: absolute;
                        top: 5px;
                        right: 20px;
                        display: block;
                        width: 27px;
                        height: 27px;
                        content: "";
                        border-radius: var(--circle-border-radius);
                        border: var(--status-indicator-border-width) solid var(--status-indicator-border-clr);
                        background-color: var(--offline-indicator-bg-clr);
                    }
                    &.online {
                        &::after {
                            background-color: var(--online-indicator-bg-clr);
                        }
                    }
                }
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                background-color: var(--primary);
                height: 24%;
                top: 0;
                left: 0;
                border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
                z-index: 0;
                opacity: 0.1;
            }
        }

        .title {
            margin-top: 7px;
            .salutation {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 16px;
                display: -webkit-box;
                margin-bottom: 4px;
                -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1;
                -webkit-box-orient: vertical;
                font-weight: 700;
                font-size: 0.8rem;
            }
            &--name {
                display: block;
                height: 25px;
                text-decoration: none;
                h2 {
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                    font-family: var(--font-family-body);
                    font-size: 1.1rem;
                    font-weight: 700;
                    color: var(--card-text-color);
                    word-break: break-all;
                    cursor: pointer;
                }
                .flag {
                    border-radius: 3px;
                }
            }

            &--bio {
                display: -webkit-box;
                height: 40px;
                overflow: hidden;
                font-size: 0.8rem;
                font-weight: 400;
                color: var(--card-second-text-color);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                p {
                    color: var(--card-second-text-color);
                }
            }
        }
        .social {
            height: 36px;
            &--wrapper {
                justify-content: center;
            }
            a {
                width: 33px;
                height: 33px;
                padding: 8px !important;
            }
            i {
                padding-top: 3px;
                font-size: 19px;
                font-weight: 500;
                color: var(--primary);
            }
        }

        .tags {
            height: 60px;
            .row {
                justify-content: center;
                .showMore {
                    display: flex;
                    align-items: center;
                    height: 26px;
                    text-decoration: none;
                    color: var(--primary);
                    font-weight: 700;
                    font-size: 13px;
                    i {
                        padding-right: 2px;
                        font-style: normal;
                    }
                }
            }

            &--item {
                text-align: center;
                max-width: 50%;
                span {
                    display: -webkit-box;
                    overflow: hidden;
                    width: inherit;
                    height: 26px;
                    line-height: 26px;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    // display: inline-grid;
                    align-content: center;
                    min-height: 26px;
                    padding: 0 12px;
                    margin: 0 3px;
                    font-size: 12px;
                    font-weight: 900;
                    color: var(--primary) !important;
                    cursor: initial !important;
                    user-select: none;
                    background-color: var(--primary-20);
                    border-radius: var(--tag-border-radius);
                    word-break: break-all;
                }

                &.show-more {
                    a {
                        background-color: var(--secondary-button-background-color);
                        border: none;
                    }
                }
            }
            &.lth2 {
                .tags--item {
                    max-width: 95%;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: center;

            button {
                min-width: 150px;
                height: max-content;
                min-height: 36px;
                padding: 0 10px !important;
            }
        }
    }
}
