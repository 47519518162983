@import "./screen-sizes";

.f16 {
  padding-bottom: 3px;
  .flag {
    width: 20px;
    height: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &.ad {
      background-image: url(../images/flags/ad.svg);
    }
    &.ad.fis {
      background-image: url(../images/flags/ad.svg);
    }
    &.ae {
      background-image: url(../images/flags/ae.svg);
    }
    &.ae.fis {
      background-image: url(../images/flags/ae.svg);
    }
    &.af {
      background-image: url(../images/flags/af.svg);
    }
    &.af.fis {
      background-image: url(../images/flags/af.svg);
    }
    &.ag {
      background-image: url(../images/flags/ag.svg);
    }
    &.ag.fis {
      background-image: url(../images/flags/ag.svg);
    }
    &.ai {
      background-image: url(../images/flags/ai.svg);
    }
    &.ai.fis {
      background-image: url(../images/flags/ai.svg);
    }
    &.al {
      background-image: url(../images/flags/al.svg);
    }
    &.al.fis {
      background-image: url(../images/flags/al.svg);
    }
    &.am {
      background-image: url(../images/flags/am.svg);
    }
    &.am.fis {
      background-image: url(../images/flags/am.svg);
    }
    &.ao {
      background-image: url(../images/flags/ao.svg);
    }
    &.ao.fis {
      background-image: url(../images/flags/ao.svg);
    }
    &.aq {
      background-image: url(../images/flags/aq.svg);
    }
    &.aq.fis {
      background-image: url(../images/flags/aq.svg);
    }
    &.ar {
      background-image: url(../images/flags/ar.svg);
    }
    &.ar.fis {
      background-image: url(../images/flags/ar.svg);
    }
    &.as {
      background-image: url(../images/flags/as.svg);
    }
    &.as.fis {
      background-image: url(../images/flags/as.svg);
    }
    &.at {
      background-image: url(../images/flags/at.svg);
    }
    &.at.fis {
      background-image: url(../images/flags/at.svg);
    }
    &.au {
      background-image: url(../images/flags/au.svg);
    }
    &.au.fis {
      background-image: url(../images/flags/au.svg);
    }
    &.aw {
      background-image: url(../images/flags/aw.svg);
    }
    &.aw.fis {
      background-image: url(../images/flags/aw.svg);
    }
    &.ax {
      background-image: url(../images/flags/ax.svg);
    }
    &.ax.fis {
      background-image: url(../images/flags/ax.svg);
    }
    &.az {
      background-image: url(../images/flags/az.svg);
    }
    &.az.fis {
      background-image: url(../images/flags/az.svg);
    }
    &.ba {
      background-image: url(../images/flags/ba.svg);
    }
    &.ba.fis {
      background-image: url(../images/flags/ba.svg);
    }
    &.bb {
      background-image: url(../images/flags/bb.svg);
    }
    &.bb.fis {
      background-image: url(../images/flags/bb.svg);
    }
    &.bd {
      background-image: url(../images/flags/bd.svg);
    }
    &.bd.fis {
      background-image: url(../images/flags/bd.svg);
    }
    &.be {
      background-image: url(../images/flags/be.svg);
    }
    &.be.fis {
      background-image: url(../images/flags/be.svg);
    }
    &.bf {
      background-image: url(../images/flags/bf.svg);
    }
    &.bf.fis {
      background-image: url(../images/flags/bf.svg);
    }
    &.bg {
      background-image: url(../images/flags/bg.svg);
    }
    &.bg.fis {
      background-image: url(../images/flags/bg.svg);
    }
    &.bh {
      background-image: url(../images/flags/bh.svg);
    }
    &.bh.fis {
      background-image: url(../images/flags/bh.svg);
    }
    &.bi {
      background-image: url(../images/flags/bi.svg);
    }
    &.bi.fis {
      background-image: url(../images/flags/bi.svg);
    }
    &.bj {
      background-image: url(../images/flags/bj.svg);
    }
    &.bj.fis {
      background-image: url(../images/flags/bj.svg);
    }
    &.bl {
      background-image: url(../images/flags/bl.svg);
    }
    &.bl.fis {
      background-image: url(../images/flags/bl.svg);
    }
    &.bm {
      background-image: url(../images/flags/bm.svg);
    }
    &.bm.fis {
      background-image: url(../images/flags/bm.svg);
    }
    &.bn {
      background-image: url(../images/flags/bn.svg);
    }
    &.bn.fis {
      background-image: url(../images/flags/bn.svg);
    }
    &.bo {
      background-image: url(../images/flags/bo.svg);
    }
    &.bo.fis {
      background-image: url(../images/flags/bo.svg);
    }
    &.bq {
      background-image: url(../images/flags/bq.svg);
    }
    &.bq.fis {
      background-image: url(../images/flags/bq.svg);
    }
    &.br {
      background-image: url(../images/flags/br.svg);
    }
    &.br.fis {
      background-image: url(../images/flags/br.svg);
    }
    &.bs {
      background-image: url(../images/flags/bs.svg);
    }
    &.bs.fis {
      background-image: url(../images/flags/bs.svg);
    }
    &.bt {
      background-image: url(../images/flags/bt.svg);
    }
    &.bt.fis {
      background-image: url(../images/flags/bt.svg);
    }
    &.bv {
      background-image: url(../images/flags/bv.svg);
    }
    &.bv.fis {
      background-image: url(../images/flags/bv.svg);
    }
    &.bw {
      background-image: url(../images/flags/bw.svg);
    }
    &.bw.fis {
      background-image: url(../images/flags/bw.svg);
    }
    &.by {
      background-image: url(../images/flags/by.svg);
    }
    &.by.fis {
      background-image: url(../images/flags/by.svg);
    }
    &.bz {
      background-image: url(../images/flags/bz.svg);
    }
    &.bz.fis {
      background-image: url(../images/flags/bz.svg);
    }
    &.ca {
      background-image: url(../images/flags/ca.svg);
    }
    &.ca.fis {
      background-image: url(../images/flags/ca.svg);
    }
    &.cc {
      background-image: url(../images/flags/cc.svg);
    }
    &.cc.fis {
      background-image: url(../images/flags/cc.svg);
    }
    &.cd {
      background-image: url(../images/flags/cd.svg);
    }
    &.cd.fis {
      background-image: url(../images/flags/cd.svg);
    }
    &.cf {
      background-image: url(../images/flags/cf.svg);
    }
    &.cf.fis {
      background-image: url(../images/flags/cf.svg);
    }
    &.cg {
      background-image: url(../images/flags/cg.svg);
    }
    &.cg.fis {
      background-image: url(../images/flags/cg.svg);
    }
    &.ch {
      background-image: url(../images/flags/ch.svg);
    }
    &.ch.fis {
      background-image: url(../images/flags/ch.svg);
    }
    &.ci {
      background-image: url(../images/flags/ci.svg);
    }
    &.ci.fis {
      background-image: url(../images/flags/ci.svg);
    }
    &.ck {
      background-image: url(../images/flags/ck.svg);
    }
    &.ck.fis {
      background-image: url(../images/flags/ck.svg);
    }
    &.cl {
      background-image: url(../images/flags/cl.svg);
    }
    &.cl.fis {
      background-image: url(../images/flags/cl.svg);
    }
    &.cm {
      background-image: url(../images/flags/cm.svg);
    }
    &.cm.fis {
      background-image: url(../images/flags/cm.svg);
    }
    &.cn {
      background-image: url(../images/flags/cn.svg);
    }
    &.cn.fis {
      background-image: url(../images/flags/cn.svg);
    }
    &.co {
      background-image: url(../images/flags/co.svg);
    }
    &.co.fis {
      background-image: url(../images/flags/co.svg);
    }
    &.cr {
      background-image: url(../images/flags/cr.svg);
    }
    &.cr.fis {
      background-image: url(../images/flags/cr.svg);
    }
    &.cu {
      background-image: url(../images/flags/cu.svg);
    }
    &.cu.fis {
      background-image: url(../images/flags/cu.svg);
    }
    &.cv {
      background-image: url(../images/flags/cv.svg);
    }
    &.cv.fis {
      background-image: url(../images/flags/cv.svg);
    }
    &.cw {
      background-image: url(../images/flags/cw.svg);
    }
    &.cw.fis {
      background-image: url(../images/flags/cw.svg);
    }
    &.cx {
      background-image: url(../images/flags/cx.svg);
    }
    &.cx.fis {
      background-image: url(../images/flags/cx.svg);
    }
    &.cy {
      background-image: url(../images/flags/cy.svg);
    }
    &.cy.fis {
      background-image: url(../images/flags/cy.svg);
    }
    &.cz {
      background-image: url(../images/flags/cz.svg);
    }
    &.cz.fis {
      background-image: url(../images/flags/cz.svg);
    }
    &.de {
      background-image: url(../images/flags/de.svg);
    }
    &.de.fis {
      background-image: url(../images/flags/de.svg);
    }
    &.dj {
      background-image: url(../images/flags/dj.svg);
    }
    &.dj.fis {
      background-image: url(../images/flags/dj.svg);
    }
    &.dk {
      background-image: url(../images/flags/dk.svg);
    }
    &.dk.fis {
      background-image: url(../images/flags/dk.svg);
    }
    &.dm {
      background-image: url(../images/flags/dm.svg);
    }
    &.dm.fis {
      background-image: url(../images/flags/dm.svg);
    }
    &.do {
      background-image: url(../images/flags/do.svg);
    }
    &.do.fis {
      background-image: url(../images/flags/do.svg);
    }
    &.dz {
      background-image: url(../images/flags/dz.svg);
    }
    &.dz.fis {
      background-image: url(../images/flags/dz.svg);
    }
    &.ec {
      background-image: url(../images/flags/ec.svg);
    }
    &.ec.fis {
      background-image: url(../images/flags/ec.svg);
    }
    &.ee {
      background-image: url(../images/flags/ee.svg);
    }
    &.ee.fis {
      background-image: url(../images/flags/ee.svg);
    }
    &.eg {
      background-image: url(../images/flags/eg.svg);
    }
    &.eg.fis {
      background-image: url(../images/flags/eg.svg);
    }
    &.eh {
      background-image: url(../images/flags/eh.svg);
    }
    &.eh.fis {
      background-image: url(../images/flags/eh.svg);
    }
    &.er {
      background-image: url(../images/flags/er.svg);
    }
    &.er.fis {
      background-image: url(../images/flags/er.svg);
    }
    &.es {
      background-image: url(../images/flags/es.svg);
    }
    &.es.fis {
      background-image: url(../images/flags/es.svg);
    }
    &.et {
      background-image: url(../images/flags/et.svg);
    }
    &.et.fis {
      background-image: url(../images/flags/et.svg);
    }
    &.fi {
      background-image: url(../images/flags/fi.svg);
    }
    &.fi.fis {
      background-image: url(../images/flags/fi.svg);
    }
    &.fj {
      background-image: url(../images/flags/fj.svg);
    }
    &.fj.fis {
      background-image: url(../images/flags/fj.svg);
    }
    &.fk {
      background-image: url(../images/flags/fk.svg);
    }
    &.fk.fis {
      background-image: url(../images/flags/fk.svg);
    }
    &.fm {
      background-image: url(../images/flags/fm.svg);
    }
    &.fm.fis {
      background-image: url(../images/flags/fm.svg);
    }
    &.fo {
      background-image: url(../images/flags/fo.svg);
    }
    &.fo.fis {
      background-image: url(../images/flags/fo.svg);
    }
    &.fr {
      background-image: url(../images/flags/fr.svg);
    }
    &.fr.fis {
      background-image: url(../images/flags/fr.svg);
    }
    &.ga {
      background-image: url(../images/flags/ga.svg);
    }
    &.ga.fis {
      background-image: url(../images/flags/ga.svg);
    }
    &.gb {
      background-image: url(../images/flags/gb.svg);
    }
    &.gb.fis {
      background-image: url(../images/flags/gb.svg);
    }
    &.gd {
      background-image: url(../images/flags/gd.svg);
    }
    &.gd.fis {
      background-image: url(../images/flags/gd.svg);
    }
    &.ge {
      background-image: url(../images/flags/ge.svg);
    }
    &.ge.fis {
      background-image: url(../images/flags/ge.svg);
    }
    &.gf {
      background-image: url(../images/flags/gf.svg);
    }
    &.gf.fis {
      background-image: url(../images/flags/gf.svg);
    }
    &.gg {
      background-image: url(../images/flags/gg.svg);
    }
    &.gg.fis {
      background-image: url(../images/flags/gg.svg);
    }
    &.gh {
      background-image: url(../images/flags/gh.svg);
    }
    &.gh.fis {
      background-image: url(../images/flags/gh.svg);
    }
    &.gi {
      background-image: url(../images/flags/gi.svg);
    }
    &.gi.fis {
      background-image: url(../images/flags/gi.svg);
    }
    &.gl {
      background-image: url(../images/flags/gl.svg);
    }
    &.gl.fis {
      background-image: url(../images/flags/gl.svg);
    }
    &.gm {
      background-image: url(../images/flags/gm.svg);
    }
    &.gm.fis {
      background-image: url(../images/flags/gm.svg);
    }
    &.gn {
      background-image: url(../images/flags/gn.svg);
    }
    &.gn.fis {
      background-image: url(../images/flags/gn.svg);
    }
    &.gp {
      background-image: url(../images/flags/gp.svg);
    }
    &.gp.fis {
      background-image: url(../images/flags/gp.svg);
    }
    &.gq {
      background-image: url(../images/flags/gq.svg);
    }
    &.gq.fis {
      background-image: url(../images/flags/gq.svg);
    }
    &.gr {
      background-image: url(../images/flags/gr.svg);
    }
    &.gr.fis {
      background-image: url(../images/flags/gr.svg);
    }
    &.gs {
      background-image: url(../images/flags/gs.svg);
    }
    &.gs.fis {
      background-image: url(../images/flags/gs.svg);
    }
    &.gt {
      background-image: url(../images/flags/gt.svg);
    }
    &.gt.fis {
      background-image: url(../images/flags/gt.svg);
    }
    &.gu {
      background-image: url(../images/flags/gu.svg);
    }
    &.gu.fis {
      background-image: url(../images/flags/gu.svg);
    }
    &.gw {
      background-image: url(../images/flags/gw.svg);
    }
    &.gw.fis {
      background-image: url(../images/flags/gw.svg);
    }
    &.gy {
      background-image: url(../images/flags/gy.svg);
    }
    &.gy.fis {
      background-image: url(../images/flags/gy.svg);
    }
    &.hk {
      background-image: url(../images/flags/hk.svg);
    }
    &.hk.fis {
      background-image: url(../images/flags/hk.svg);
    }
    &.hm {
      background-image: url(../images/flags/hm.svg);
    }
    &.hm.fis {
      background-image: url(../images/flags/hm.svg);
    }
    &.hn {
      background-image: url(../images/flags/hn.svg);
    }
    &.hn.fis {
      background-image: url(../images/flags/hn.svg);
    }
    &.hr {
      background-image: url(../images/flags/hr.svg);
    }
    &.hr.fis {
      background-image: url(../images/flags/hr.svg);
    }
    &.ht {
      background-image: url(../images/flags/ht.svg);
    }
    &.ht.fis {
      background-image: url(../images/flags/ht.svg);
    }
    &.hu {
      background-image: url(../images/flags/hu.svg);
    }
    &.hu.fis {
      background-image: url(../images/flags/hu.svg);
    }
    &.id {
      background-image: url(../images/flags/id.svg);
    }
    &.id.fis {
      background-image: url(../images/flags/id.svg);
    }
    &.ie {
      background-image: url(../images/flags/ie.svg);
    }
    &.ie.fis {
      background-image: url(../images/flags/ie.svg);
    }
    &.il {
      background-image: url(../images/flags/il.svg);
    }
    &.il.fis {
      background-image: url(../images/flags/il.svg);
    }
    &.im {
      background-image: url(../images/flags/im.svg);
    }
    &.im.fis {
      background-image: url(../images/flags/im.svg);
    }
    &.in {
      background-image: url(../images/flags/in.svg);
    }
    &.in.fis {
      background-image: url(../images/flags/in.svg);
    }
    &.io {
      background-image: url(../images/flags/io.svg);
    }
    &.io.fis {
      background-image: url(../images/flags/io.svg);
    }
    &.iq {
      background-image: url(../images/flags/iq.svg);
    }
    &.iq.fis {
      background-image: url(../images/flags/iq.svg);
    }
    &.ir {
      background-image: url(../images/flags/ir.svg);
    }
    &.ir.fis {
      background-image: url(../images/flags/ir.svg);
    }
    &.is {
      background-image: url(../images/flags/is.svg);
    }
    &.is.fis {
      background-image: url(../images/flags/is.svg);
    }
    &.it {
      background-image: url(../images/flags/it.svg);
    }
    &.it.fis {
      background-image: url(../images/flags/it.svg);
    }
    &.je {
      background-image: url(../images/flags/je.svg);
    }
    &.je.fis {
      background-image: url(../images/flags/je.svg);
    }
    &.jm {
      background-image: url(../images/flags/jm.svg);
    }
    &.jm.fis {
      background-image: url(../images/flags/jm.svg);
    }
    &.jo {
      background-image: url(../images/flags/jo.svg);
    }
    &.jo.fis {
      background-image: url(../images/flags/jo.svg);
    }
    &.jp {
      background-image: url(../images/flags/jp.svg);
    }
    &.jp.fis {
      background-image: url(../images/flags/jp.svg);
    }
    &.ke {
      background-image: url(../images/flags/ke.svg);
    }
    &.ke.fis {
      background-image: url(../images/flags/ke.svg);
    }
    &.kg {
      background-image: url(../images/flags/kg.svg);
    }
    &.kg.fis {
      background-image: url(../images/flags/kg.svg);
    }
    &.kh {
      background-image: url(../images/flags/kh.svg);
    }
    &.kh.fis {
      background-image: url(../images/flags/kh.svg);
    }
    &.ki {
      background-image: url(../images/flags/ki.svg);
    }
    &.ki.fis {
      background-image: url(../images/flags/ki.svg);
    }
    &.km {
      background-image: url(../images/flags/km.svg);
    }
    &.km.fis {
      background-image: url(../images/flags/km.svg);
    }
    &.kn {
      background-image: url(../images/flags/kn.svg);
    }
    &.kn.fis {
      background-image: url(../images/flags/kn.svg);
    }
    &.kp {
      background-image: url(../images/flags/kp.svg);
    }
    &.kp.fis {
      background-image: url(../images/flags/kp.svg);
    }
    &.kr {
      background-image: url(../images/flags/kr.svg);
    }
    &.kr.fis {
      background-image: url(../images/flags/kr.svg);
    }
    &.kw {
      background-image: url(../images/flags/kw.svg);
    }
    &.kw.fis {
      background-image: url(../images/flags/kw.svg);
    }
    &.ky {
      background-image: url(../images/flags/ky.svg);
    }
    &.ky.fis {
      background-image: url(../images/flags/ky.svg);
    }
    &.kz {
      background-image: url(../images/flags/kz.svg);
    }
    &.kz.fis {
      background-image: url(../images/flags/kz.svg);
    }
    &.la {
      background-image: url(../images/flags/la.svg);
    }
    &.la.fis {
      background-image: url(../images/flags/la.svg);
    }
    &.lb {
      background-image: url(../images/flags/lb.svg);
    }
    &.lb.fis {
      background-image: url(../images/flags/lb.svg);
    }
    &.lc {
      background-image: url(../images/flags/lc.svg);
    }
    &.lc.fis {
      background-image: url(../images/flags/lc.svg);
    }
    &.li {
      background-image: url(../images/flags/li.svg);
    }
    &.li.fis {
      background-image: url(../images/flags/li.svg);
    }
    &.lk {
      background-image: url(../images/flags/lk.svg);
    }
    &.lk.fis {
      background-image: url(../images/flags/lk.svg);
    }
    &.lr {
      background-image: url(../images/flags/lr.svg);
    }
    &.lr.fis {
      background-image: url(../images/flags/lr.svg);
    }
    &.ls {
      background-image: url(../images/flags/ls.svg);
    }
    &.ls.fis {
      background-image: url(../images/flags/ls.svg);
    }
    &.lt {
      background-image: url(../images/flags/lt.svg);
    }
    &.lt.fis {
      background-image: url(../images/flags/lt.svg);
    }
    &.lu {
      background-image: url(../images/flags/lu.svg);
    }
    &.lu.fis {
      background-image: url(../images/flags/lu.svg);
    }
    &.lv {
      background-image: url(../images/flags/lv.svg);
    }
    &.lv.fis {
      background-image: url(../images/flags/lv.svg);
    }
    &.ly {
      background-image: url(../images/flags/ly.svg);
    }
    &.ly.fis {
      background-image: url(../images/flags/ly.svg);
    }
    &.ma {
      background-image: url(../images/flags/ma.svg);
    }
    &.ma.fis {
      background-image: url(../images/flags/ma.svg);
    }
    &.mc {
      background-image: url(../images/flags/mc.svg);
    }
    &.mc.fis {
      background-image: url(../images/flags/mc.svg);
    }
    &.md {
      background-image: url(../images/flags/md.svg);
    }
    &.md.fis {
      background-image: url(../images/flags/md.svg);
    }
    &.me {
      background-image: url(../images/flags/me.svg);
    }
    &.me.fis {
      background-image: url(../images/flags/me.svg);
    }
    &.mf {
      background-image: url(../images/flags/mf.svg);
    }
    &.mf.fis {
      background-image: url(../images/flags/mf.svg);
    }
    &.mg {
      background-image: url(../images/flags/mg.svg);
    }
    &.mg.fis {
      background-image: url(../images/flags/mg.svg);
    }
    &.mh {
      background-image: url(../images/flags/mh.svg);
    }
    &.mh.fis {
      background-image: url(../images/flags/mh.svg);
    }
    &.mk {
      background-image: url(../images/flags/mk.svg);
    }
    &.mk.fis {
      background-image: url(../images/flags/mk.svg);
    }
    &.ml {
      background-image: url(../images/flags/ml.svg);
    }
    &.ml.fis {
      background-image: url(../images/flags/ml.svg);
    }
    &.mm {
      background-image: url(../images/flags/mm.svg);
    }
    &.mm.fis {
      background-image: url(../images/flags/mm.svg);
    }
    &.mn {
      background-image: url(../images/flags/mn.svg);
    }
    &.mn.fis {
      background-image: url(../images/flags/mn.svg);
    }
    &.mo {
      background-image: url(../images/flags/mo.svg);
    }
    &.mo.fis {
      background-image: url(../images/flags/mo.svg);
    }
    &.mp {
      background-image: url(../images/flags/mp.svg);
    }
    &.mp.fis {
      background-image: url(../images/flags/mp.svg);
    }
    &.mq {
      background-image: url(../images/flags/mq.svg);
    }
    &.mq.fis {
      background-image: url(../images/flags/mq.svg);
    }
    &.mr {
      background-image: url(../images/flags/mr.svg);
    }
    &.mr.fis {
      background-image: url(../images/flags/mr.svg);
    }
    &.ms {
      background-image: url(../images/flags/ms.svg);
    }
    &.ms.fis {
      background-image: url(../images/flags/ms.svg);
    }
    &.mt {
      background-image: url(../images/flags/mt.svg);
    }
    &.mt.fis {
      background-image: url(../images/flags/mt.svg);
    }
    &.mu {
      background-image: url(../images/flags/mu.svg);
    }
    &.mu.fis {
      background-image: url(../images/flags/mu.svg);
    }
    &.mv {
      background-image: url(../images/flags/mv.svg);
    }
    &.mv.fis {
      background-image: url(../images/flags/mv.svg);
    }
    &.mw {
      background-image: url(../images/flags/mw.svg);
    }
    &.mw.fis {
      background-image: url(../images/flags/mw.svg);
    }
    &.mx {
      background-image: url(../images/flags/mx.svg);
    }
    &.mx.fis {
      background-image: url(../images/flags/mx.svg);
    }
    &.my {
      background-image: url(../images/flags/my.svg);
    }
    &.my.fis {
      background-image: url(../images/flags/my.svg);
    }
    &.mz {
      background-image: url(../images/flags/mz.svg);
    }
    &.mz.fis {
      background-image: url(../images/flags/mz.svg);
    }
    &.na {
      background-image: url(../images/flags/na.svg);
    }
    &.na.fis {
      background-image: url(../images/flags/na.svg);
    }
    &.nc {
      background-image: url(../images/flags/nc.svg);
    }
    &.nc.fis {
      background-image: url(../images/flags/nc.svg);
    }
    &.ne {
      background-image: url(../images/flags/ne.svg);
    }
    &.ne.fis {
      background-image: url(../images/flags/ne.svg);
    }
    &.nf {
      background-image: url(../images/flags/nf.svg);
    }
    &.nf.fis {
      background-image: url(../images/flags/nf.svg);
    }
    &.ng {
      background-image: url(../images/flags/ng.svg);
    }
    &.ng.fis {
      background-image: url(../images/flags/ng.svg);
    }
    &.ni {
      background-image: url(../images/flags/ni.svg);
    }
    &.ni.fis {
      background-image: url(../images/flags/ni.svg);
    }
    &.nl {
      background-image: url(../images/flags/nl.svg);
    }
    &.nl.fis {
      background-image: url(../images/flags/nl.svg);
    }
    &.no {
      background-image: url(../images/flags/no.svg);
    }
    &.no.fis {
      background-image: url(../images/flags/no.svg);
    }
    &.np {
      background-image: url(../images/flags/np.svg);
    }
    &.np.fis {
      background-image: url(../images/flags/np.svg);
    }
    &.nr {
      background-image: url(../images/flags/nr.svg);
    }
    &.nr.fis {
      background-image: url(../images/flags/nr.svg);
    }
    &.nu {
      background-image: url(../images/flags/nu.svg);
    }
    &.nu.fis {
      background-image: url(../images/flags/nu.svg);
    }
    &.nz {
      background-image: url(../images/flags/nz.svg);
    }
    &.nz.fis {
      background-image: url(../images/flags/nz.svg);
    }
    &.om {
      background-image: url(../images/flags/om.svg);
    }
    &.om.fis {
      background-image: url(../images/flags/om.svg);
    }
    &.pa {
      background-image: url(../images/flags/pa.svg);
    }
    &.pa.fis {
      background-image: url(../images/flags/pa.svg);
    }
    &.pe {
      background-image: url(../images/flags/pe.svg);
    }
    &.pe.fis {
      background-image: url(../images/flags/pe.svg);
    }
    &.pf {
      background-image: url(../images/flags/pf.svg);
    }
    &.pf.fis {
      background-image: url(../images/flags/pf.svg);
    }
    &.pg {
      background-image: url(../images/flags/pg.svg);
    }
    &.pg.fis {
      background-image: url(../images/flags/pg.svg);
    }
    &.ph {
      background-image: url(../images/flags/ph.svg);
    }
    &.ph.fis {
      background-image: url(../images/flags/ph.svg);
    }
    &.pk {
      background-image: url(../images/flags/pk.svg);
    }
    &.pk.fis {
      background-image: url(../images/flags/pk.svg);
    }
    &.pl {
      background-image: url(../images/flags/pl.svg);
    }
    &.pl.fis {
      background-image: url(../images/flags/pl.svg);
    }
    &.pm {
      background-image: url(../images/flags/pm.svg);
    }
    &.pm.fis {
      background-image: url(../images/flags/pm.svg);
    }
    &.pn {
      background-image: url(../images/flags/pn.svg);
    }
    &.pn.fis {
      background-image: url(../images/flags/pn.svg);
    }
    &.pr {
      background-image: url(../images/flags/pr.svg);
    }
    &.pr.fis {
      background-image: url(../images/flags/pr.svg);
    }
    &.ps {
      background-image: url(../images/flags/ps.svg);
    }
    &.ps.fis {
      background-image: url(../images/flags/ps.svg);
    }
    &.pt {
      background-image: url(../images/flags/pt.svg);
    }
    &.pt.fis {
      background-image: url(../images/flags/pt.svg);
    }
    &.pw {
      background-image: url(../images/flags/pw.svg);
    }
    &.pw.fis {
      background-image: url(../images/flags/pw.svg);
    }
    &.py {
      background-image: url(../images/flags/py.svg);
    }
    &.py.fis {
      background-image: url(../images/flags/py.svg);
    }
    &.qa {
      background-image: url(../images/flags/qa.svg);
    }
    &.qa.fis {
      background-image: url(../images/flags/qa.svg);
    }
    &.re {
      background-image: url(../images/flags/re.svg);
    }
    &.re.fis {
      background-image: url(../images/flags/re.svg);
    }
    &.ro {
      background-image: url(../images/flags/ro.svg);
    }
    &.ro.fis {
      background-image: url(../images/flags/ro.svg);
    }
    &.rs {
      background-image: url(../images/flags/rs.svg);
    }
    &.rs.fis {
      background-image: url(../images/flags/rs.svg);
    }
    &.ru {
      background-image: url(../images/flags/ru.svg);
    }
    &.ru.fis {
      background-image: url(../images/flags/ru.svg);
    }
    &.rw {
      background-image: url(../images/flags/rw.svg);
    }
    &.rw.fis {
      background-image: url(../images/flags/rw.svg);
    }
    &.sa {
      background-image: url(../images/flags/sa.svg);
    }
    &.sa.fis {
      background-image: url(../images/flags/sa.svg);
    }
    &.sb {
      background-image: url(../images/flags/sb.svg);
    }
    &.sb.fis {
      background-image: url(../images/flags/sb.svg);
    }
    &.sc {
      background-image: url(../images/flags/sc.svg);
    }
    &.sc.fis {
      background-image: url(../images/flags/sc.svg);
    }
    &.sd {
      background-image: url(../images/flags/sd.svg);
    }
    &.sd.fis {
      background-image: url(../images/flags/sd.svg);
    }
    &.se {
      background-image: url(../images/flags/se.svg);
    }
    &.se.fis {
      background-image: url(../images/flags/se.svg);
    }
    &.sg {
      background-image: url(../images/flags/sg.svg);
    }
    &.sg.fis {
      background-image: url(../images/flags/sg.svg);
    }
    &.sh {
      background-image: url(../images/flags/sh.svg);
    }
    &.sh.fis {
      background-image: url(../images/flags/sh.svg);
    }
    &.si {
      background-image: url(../images/flags/si.svg);
    }
    &.si.fis {
      background-image: url(../images/flags/si.svg);
    }
    &.sj {
      background-image: url(../images/flags/sj.svg);
    }
    &.sj.fis {
      background-image: url(../images/flags/sj.svg);
    }
    &.sk {
      background-image: url(../images/flags/sk.svg);
    }
    &.sk.fis {
      background-image: url(../images/flags/sk.svg);
    }
    &.sl {
      background-image: url(../images/flags/sl.svg);
    }
    &.sl.fis {
      background-image: url(../images/flags/sl.svg);
    }
    &.sm {
      background-image: url(../images/flags/sm.svg);
    }
    &.sm.fis {
      background-image: url(../images/flags/sm.svg);
    }
    &.sn {
      background-image: url(../images/flags/sn.svg);
    }
    &.sn.fis {
      background-image: url(../images/flags/sn.svg);
    }
    &.so {
      background-image: url(../images/flags/so.svg);
    }
    &.so.fis {
      background-image: url(../images/flags/so.svg);
    }
    &.sr {
      background-image: url(../images/flags/sr.svg);
    }
    &.sr.fis {
      background-image: url(../images/flags/sr.svg);
    }
    &.ss {
      background-image: url(../images/flags/ss.svg);
    }
    &.ss.fis {
      background-image: url(../images/flags/ss.svg);
    }
    &.st {
      background-image: url(../images/flags/st.svg);
    }
    &.st.fis {
      background-image: url(../images/flags/st.svg);
    }
    &.sv {
      background-image: url(../images/flags/sv.svg);
    }
    &.sv.fis {
      background-image: url(../images/flags/sv.svg);
    }
    &.sx {
      background-image: url(../images/flags/sx.svg);
    }
    &.sx.fis {
      background-image: url(../images/flags/sx.svg);
    }
    &.sy {
      background-image: url(../images/flags/sy.svg);
    }
    &.sy.fis {
      background-image: url(../images/flags/sy.svg);
    }
    &.sz {
      background-image: url(../images/flags/sz.svg);
    }
    &.sz.fis {
      background-image: url(../images/flags/sz.svg);
    }
    &.tc {
      background-image: url(../images/flags/tc.svg);
    }
    &.tc.fis {
      background-image: url(../images/flags/tc.svg);
    }
    &.td {
      background-image: url(../images/flags/td.svg);
    }
    &.td.fis {
      background-image: url(../images/flags/td.svg);
    }
    &.tf {
      background-image: url(../images/flags/tf.svg);
    }
    &.tf.fis {
      background-image: url(../images/flags/tf.svg);
    }
    &.tg {
      background-image: url(../images/flags/tg.svg);
    }
    &.tg.fis {
      background-image: url(../images/flags/tg.svg);
    }
    &.th {
      background-image: url(../images/flags/th.svg);
    }
    &.th.fis {
      background-image: url(../images/flags/th.svg);
    }
    &.tj {
      background-image: url(../images/flags/tj.svg);
    }
    &.tj.fis {
      background-image: url(../images/flags/tj.svg);
    }
    &.tk {
      background-image: url(../images/flags/tk.svg);
    }
    &.tk.fis {
      background-image: url(../images/flags/tk.svg);
    }
    &.tl {
      background-image: url(../images/flags/tl.svg);
    }
    &.tl.fis {
      background-image: url(../images/flags/tl.svg);
    }
    &.tm {
      background-image: url(../images/flags/tm.svg);
    }
    &.tm.fis {
      background-image: url(../images/flags/tm.svg);
    }
    &.tn {
      background-image: url(../images/flags/tn.svg);
    }
    &.tn.fis {
      background-image: url(../images/flags/tn.svg);
    }
    &.to {
      background-image: url(../images/flags/to.svg);
    }
    &.to.fis {
      background-image: url(../images/flags/to.svg);
    }
    &.tr {
      background-image: url(../images/flags/tr.svg);
    }
    &.tr.fis {
      background-image: url(../images/flags/tr.svg);
    }
    &.tt {
      background-image: url(../images/flags/tt.svg);
    }
    &.tt.fis {
      background-image: url(../images/flags/tt.svg);
    }
    &.tv {
      background-image: url(../images/flags/tv.svg);
    }
    &.tv.fis {
      background-image: url(../images/flags/tv.svg);
    }
    &.tw {
      background-image: url(../images/flags/tw.svg);
    }
    &.tw.fis {
      background-image: url(../images/flags/tw.svg);
    }
    &.tz {
      background-image: url(../images/flags/tz.svg);
    }
    &.tz.fis {
      background-image: url(../images/flags/tz.svg);
    }
    &.ua {
      background-image: url(../images/flags/ua.svg);
    }
    &.ua.fis {
      background-image: url(../images/flags/ua.svg);
    }
    &.ug {
      background-image: url(../images/flags/ug.svg);
    }
    &.ug.fis {
      background-image: url(../images/flags/ug.svg);
    }
    &.um {
      background-image: url(../images/flags/um.svg);
    }
    &.um.fis {
      background-image: url(../images/flags/um.svg);
    }
    &.us {
      background-image: url(../images/flags/us.svg);
    }
    &.us.fis {
      background-image: url(../images/flags/us.svg);
    }
    &.uy {
      background-image: url(../images/flags/uy.svg);
    }
    &.uy.fis {
      background-image: url(../images/flags/uy.svg);
    }
    &.uz {
      background-image: url(../images/flags/uz.svg);
    }
    &.uz.fis {
      background-image: url(../images/flags/uz.svg);
    }
    &.va {
      background-image: url(../images/flags/va.svg);
    }
    &.va.fis {
      background-image: url(../images/flags/va.svg);
    }
    &.vc {
      background-image: url(../images/flags/vc.svg);
    }
    &.vc.fis {
      background-image: url(../images/flags/vc.svg);
    }
    &.ve {
      background-image: url(../images/flags/ve.svg);
    }
    &.ve.fis {
      background-image: url(../images/flags/ve.svg);
    }
    &.vg {
      background-image: url(../images/flags/vg.svg);
    }
    &.vg.fis {
      background-image: url(../images/flags/vg.svg);
    }
    &.vi {
      background-image: url(../images/flags/vi.svg);
    }
    &.vi.fis {
      background-image: url(../images/flags/vi.svg);
    }
    &.vn {
      background-image: url(../images/flags/vn.svg);
    }
    &.vn.fis {
      background-image: url(../images/flags/vn.svg);
    }
    &.vu {
      background-image: url(../images/flags/vu.svg);
    }
    &.vu.fis {
      background-image: url(../images/flags/vu.svg);
    }
    &.wf {
      background-image: url(../images/flags/wf.svg);
    }
    &.wf.fis {
      background-image: url(../images/flags/wf.svg);
    }
    &.ws {
      background-image: url(../images/flags/ws.svg);
    }
    &.ws.fis {
      background-image: url(../images/flags/ws.svg);
    }
    &.ye {
      background-image: url(../images/flags/ye.svg);
    }
    &.ye.fis {
      background-image: url(../images/flags/ye.svg);
    }
    &.yt {
      background-image: url(../images/flags/yt.svg);
    }
    &.yt.fis {
      background-image: url(../images/flags/yt.svg);
    }
    &.za {
      background-image: url(../images/flags/za.svg);
    }
    &.za.fis {
      background-image: url(../images/flags/za.svg);
    }
    &.zm {
      background-image: url(../images/flags/zm.svg);
    }
    &.zm.fis {
      background-image: url(../images/flags/zm.svg);
    }
    &.zw {
      background-image: url(../images/flags/zw.svg);
    }
    &.zw.fis {
      background-image: url(../images/flags/zw.svg);
    }
    &.ac {
      background-image: url(../images/flags/ac.svg);
    }
    &.ac.fis {
      background-image: url(../images/flags/ac.svg);
    }
    &.cp {
      background-image: url(../images/flags/cp.svg);
    }
    &.cp.fis {
      background-image: url(../images/flags/cp.svg);
    }
    &.dg {
      background-image: url(../images/flags/dg.svg);
    }
    &.dg.fis {
      background-image: url(../images/flags/dg.svg);
    }
    &.ea {
      background-image: url(../images/flags/ea.svg);
    }
    &.ea.fis {
      background-image: url(../images/flags/ea.svg);
    }
    &.es-ct {
      background-image: url(../images/flags/es-ct.svg);
    }
    &.es-ct.fis {
      background-image: url(../images/flags/es-ct.svg);
    }
    &.es-ga {
      background-image: url(../images/flags/es-ga.svg);
    }
    &.es-ga.fis {
      background-image: url(../images/flags/es-ga.svg);
    }
    &.eu {
      background-image: url(../images/flags/eu.svg);
    }
    &.eu.fis {
      background-image: url(../images/flags/eu.svg);
    }
    &.gb-eng {
      background-image: url(../images/flags/gb-eng.svg);
    }
    &.gb-eng.fis {
      background-image: url(../images/flags/gb-eng.svg);
    }
    &.gb-nir {
      background-image: url(../images/flags/gb-nir.svg);
    }
    &.gb-nir.fis {
      background-image: url(../images/flags/gb-nir.svg);
    }
    &.gb-sct {
      background-image: url(../images/flags/gb-sct.svg);
    }
    &.gb-sct.fis {
      background-image: url(../images/flags/gb-sct.svg);
    }
    &.gb-wls {
      background-image: url(../images/flags/gb-wls.svg);
    }
    &.gb-wls.fis {
      background-image: url(../images/flags/gb-wls.svg);
    }
    &.ic {
      background-image: url(../images/flags/ic.svg);
    }
    &.ic.fis {
      background-image: url(../images/flags/ic.svg);
    }
    &.ta {
      background-image: url(../images/flags/ta.svg);
    }
    &.ta.fis {
      background-image: url(../images/flags/ta.svg);
    }
    &.un {
      background-image: url(../images/flags/un.svg);
    }
    &.un.fis {
      background-image: url(../images/flags/un.svg);
    }
    &.xk {
      background-image: url(../images/flags/xk.svg);
    }
    &.xk.fis {
      background-image: url(../images/flags/xk.svg);
    }
    &.xx {
      background-image: url(../images/flags/xx.svg);
    }
    &.xx.fis {
      background-image: url(../images/flags/xx.svg);
    }
  }
  .lang {
    width: 18px;
    height: 18px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &.all {
      background-image: url(../images/flags-lang/all.svg);
    }
    &.en {
      background-image: url(../images/flags-lang/en.svg);
    }
    &.fr {
      background-image: url(../images/flags-lang/fr.svg);
    }
    &.it {
      background-image: url(../images/flags-lang/it.svg);
    }
    &.de {
      background-image: url(../images/flags-lang/de.svg);
    }
    &.zh {
      background-image: url(../images/flags-lang/zh.svg);
    }
    &.ru {
      background-image: url(../images/flags-lang/ru.svg);
    }
    &.pt {
      background-image: url(../images/flags-lang/pt.svg);
    }
    &.ar {
      background-image: url(../images/flags-lang/ar.svg);
    }
  }
}
