@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.badge-container {
    $borderWidth: 28px;
    $border-color-default: transparent;
    $border-color-fill: var(--primary);
    $size: 50px;
    width: var(--badge-size);
    height: var(--badge-size);
    // @include media("mcbk-1") {
    //     width: 125px;
    //     height: 125px;
    // }
    // @include media("mcbk-2") {
    //     width: 125px;
    //     height: 125px;
    // }
    .progress {
        width: var(--badge-size);
        height: var(--badge-size);
        background: none;
        margin: 0 auto;
        box-shadow: none;
        position: relative;
        &:after {
            content: "";
            width: 100%;
            border: $borderWidth solid $border-color-default;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
        }
        > span {
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 1;
        }
        .progress-bar {
            width: 100%;
            height: 100%;
            background: none;
            border-width: $borderWidth;
            position: absolute;
            top: 0;
            border: $borderWidth solid transparent;
        }
        .progress-left {
            left: 0;
            transform-origin: center right;
            .progress-bar {
                left: 100%;
                border-top-right-radius: 500px;
                border-bottom-right-radius: 500px;
                border-left: 0;
                -webkit-transform-origin: center left;
                transform-origin: center left;
            }
        }
        .progress-right {
            transform-origin: center left;
            right: 0;
            .progress-bar {
                left: -100%;
                border-top-left-radius: 500px;
                border-bottom-left-radius: 500px;
                border-right: 0;
                -webkit-transform-origin: center right;
                transform-origin: center right;
                border-image-slice: 60 30;
                background: linear-gradient(45deg, #000000, rgba(0, 0, 0, 0) 100%) border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
                box-shadow: inset 0px 100px 0px 0px rgba(255, 255, 255, 0.6);
                mask-composite: exclude;
                &.online {
                    background: linear-gradient(45deg, #2cda9d, rgba(0, 0, 0, 0) 100%) border-box;
                }
            }
        }
    }

    @for $i from 1 through 100 {
        @if $i <= (50) {
            $degrees: (180/50);
            $degrees: ($degrees * $i);
            .progress[data-percentage="#{$i}"] {
                .progress-right .progress-bar {
                    -webkit-transform: rotate($degrees);
                    transform: rotate(#{$degrees}deg);
                }
                .progress-left .progress-bar {
                    animation: 0;
                }
            }
        }
        @if $i > (50) {
            $degrees: (180/50);
            $degrees: ($degrees * ($i - 50));
            .progress[data-percentage="#{$i}"] {
                .progress-right .progress-bar {
                    -webkit-transform: rotate(180);
                    transform: rotate(180deg);
                }
                .progress-left .progress-bar {
                    -webkit-transform: rotate($degrees);
                    transform: rotate(#{$degrees}deg);
                }
            }
        }
    }
}
