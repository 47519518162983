@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.loaderContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    height: 76vh;
    z-index: 10;
    &.centerLoader {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
    }

    &.custom-btn-loader {
        position: relative;
        display: block;
        width: unset;
        height: unset;
    }
    &.overlay-modal-loader {
        position: absolute;
        background-color: var(--overlay-white-bg-clr);
        height: 100%;
    }

    &.load-more-loader {
        bottom: 0 !important;
        top: unset;
        height: 10%;
        position: initial;
        width: 100% !important;
    }

    .appLoader {
        margin: auto;
    }
    &.blankPageCenterLoader {
        top: 0;
        width: 100%;
        height: 100vh;
    }
    &.updatePageLoader {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
    }
    .default-spinner {
        .dot-pulse {
            position: relative;
            left: -9983px;
            width: 4px;
            height: 4px;
            border-radius: 5px;
            background-color: var(--primary);
            color: var(--primary);
            box-shadow: 9999px 0 0 -5px var(--primary);
            animation: dotPulse 1.2s infinite linear;
            animation-delay: 0.11s;
            &::before,
            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0;
                width: 4px;
                height: 4px;
                border-radius: 5px;
                background-color: var(--primary);
                color: var(--primary);
            }
            &::before {
                box-shadow: 9984px 0 0 -5px var(--primary);
                animation: dotPulseBefore 1.5s infinite linear;
                animation-delay: 0s;
            }
            &::after {
                box-shadow: 10014px 0 0 -5px var(--primary);
                animation: dotPulseAfter 1.5s infinite linear;
                animation-delay: 0.5s;
            }
            @keyframes dotPulseBefore {
                0% {
                    box-shadow: 9984px 0 0 -5px var(--primary);
                }
                30% {
                    box-shadow: 9984px 0 0 2px var(--primary);
                }
                60% {
                    box-shadow: 9984px 0 0 -5px var(--primary);
                }
                100% {
                    box-shadow: 9984px 0 0 -5px var(--primary);
                }
            }

            @keyframes dotPulse {
                0% {
                    box-shadow: 9999px 0 0 -5px var(--primary);
                }
                30% {
                    box-shadow: 9999px 0 0 2px var(--primary);
                }
                60% {
                    box-shadow: 9999px 0 0 -5px var(--primary);
                }
                100% {
                    box-shadow: 9999px 0 0 -5px var(--primary);
                }
            }

            @keyframes dotPulseAfter {
                0% {
                    box-shadow: 10014px 0 0 -5px var(--primary);
                }
                30% {
                    box-shadow: 10014px 0 0 2px var(--primary);
                }
                60% {
                    box-shadow: 10014px 0 0 -5px var(--primary);
                }
                100% {
                    box-shadow: 10014px 0 0 -5px var(--primary);
                }
            }
        }
    }
}
