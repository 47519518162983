@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";
:root {
    --course-category-background-color: var(--primary);
}
.course-item {
    margin-bottom: 30px;
    &--card {
        cursor: pointer;
        .banner {
            &--content {
                position: relative;
                overflow: hidden;
                content: "";
                aspect-ratio: 16/9;
                border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                .new-ribbon {
                    position: absolute;
                    top: 0px;
                    left: -40px;
                    transform: rotate(-43deg);
                    .content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 140px;
                        height: 28px;
                        padding: 12px 6px 10px 6px;
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--light-clr);
                        background-color: var(--danger);
                        box-shadow: 0px 3px 9px #9ba7ba;

                        &:hover {
                            text-decoration: none;
                        }
                        span {
                            display: -webkit-box;
                            overflow: hidden;
                            width: 50px;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }
                        i {
                            margin-right: 2px;
                            margin-bottom: 2px;
                            font-size: 18px;
                        }
                    }
                }
                .dateBadge {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    .content {
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        height: 55px;
                        background-color: var(--light-clr);
                        border-radius: 3px;
                        box-shadow: 0 3px 9px var(--card-box-shadow-color);

                        .day {
                            display: flex;
                            align-items: center;
                            padding: 5px 10px;
                            line-height: normal;
                            span {
                                padding-top: 7px;
                                font-size: 2.3rem;
                                font-weight: 900;
                                color: var(--primary);
                            }
                        }
                        .time {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            height: inherit;
                            padding: 0 10px;
                            .month {
                                display: -webkit-box;
                                overflow: hidden;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                max-width: 120px;
                                padding-top: 3px;
                                font-size: 1rem;
                                font-weight: 700;
                                color: var(--primary);
                                line-height: normal;
                                border-bottom: 2px solid var(--primary);
                            }
                            .clock {
                                padding-top: 2px;
                                font-size: 0.7rem;
                                font-weight: 700;
                                color: var(--primary);
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background-color: var(--primary);
                                opacity: 0.2;
                            }
                        }
                        .year {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: inherit;
                            background-color: var(--primary);
                            span {
                                font-size: 1rem;
                                font-weight: 700;
                                padding-right: 2px;
                                writing-mode: vertical-rl;
                                text-orientation: mixed;
                                transform: rotate(180deg);
                                color: var(--light-clr);
                            }
                        }
                    }
                }
            }

            .category {
                position: absolute;
                top: 69px;
                left: -10.5px;
                .content {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    height: 28px;

                    padding: 0 10px;
                    background-color: var(--course-category-background-color);
                    border-radius: 3px;
                    span {
                        display: -webkit-box;
                        min-width: fit-content;
                        max-width: inherit;
                        overflow: hidden;
                        padding-top: 2px;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        font-size: 0.8rem;
                        font-weight: 700;
                        color: var(--light-clr);
                        word-break: break-word;
                    }
                    &:after {
                        content: "\e04a";
                        font-family: "Font Awesome Kit";
                        transform: rotate(180deg);
                        font-size: 11.5px;
                        position: absolute;
                        left: 0.5px;
                        top: -7.5px;
                        color: var(--course-category-background-color);
                    }
                }
            }
        }
        .context {
            z-index: var(--z-index-none);
            margin-top: -20px;
            margin-left: 20px;
            margin-right: 20px;
            &--inner {
                position: relative;
                padding: 15px;
                background-color: var(--card-background-color);
                border-radius: var(--card-border-radius);
                .header {
                    display: flex;
                    height: 45px;
                    .title {
                        h3 {
                            display: -webkit-box;
                            width: fit-content;
                            font-size: 1.1rem;
                            font-weight: 700;
                            color: var(--card-text-color);
                            margin-bottom: 0;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            cursor: pointer;
                        }
                    }
                    .actions {
                        padding-left: 5px;
                        .shareBtn {
                            width: 25px;
                            height: 25px;
                            padding: 0 !important;
                            i {
                                font-weight: 500;
                            }
                        }
                    }
                }
                .desc {
                    height: 85px;
                    .text {
                        display: -webkit-box;
                        width: fit-content;
                        margin-bottom: 0;
                        font-size: 0.9rem;
                        font-weight: 400;
                        line-height: 1.2rem;
                        color: var(--card-text-color);
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                    }
                    .showMore {
                        a {
                            font-size: 0.7rem;
                            font-weight: 900;
                            line-height: normal;
                            color: var(--primary);
                            text-decoration: none;
                        }
                    }
                }
                .info {
                    height: 50px;
                    > .row {
                        .info--item {
                            .content {
                                &--icon {
                                    padding-right: 8px;

                                    i {
                                        font-size: 1.3rem;
                                        font-weight: 500;
                                    }
                                }
                                &--desc {
                                    display: inline-grid;
                                    padding-top: 3px;
                                    span {
                                        line-height: normal;
                                    }
                                    .count {
                                        font-size: 0.9rem;
                                        font-weight: 700;
                                        color: var(--card-text-color);
                                    }
                                    .field {
                                        font-size: 0.7rem;
                                        font-weight: 900;
                                        color: var(--card-second-text-color);
                                    }
                                }
                            }
                        }
                    }
                }

                .actions {
                    display: flex;
                    height: 40px;
                    .registerBtn {
                        margin-right: 8px;
                        padding-left: 8px;
                        .btn {
                            height: 34px;
                            padding: 0 35px !important;
                            font-size: 0.8rem !important;
                        }
                    }
                }
            }
        }
        .additional-info {
            &--inner {
                padding: 15px;
                .users {
                    .users-list {
                        margin-bottom: 10px;
                    }
                }
                .presenters {
                    &--inner {
                        .title {
                            h4 {
                                display: flex;
                                align-items: center;
                                margin-bottom: 0;
                                i {
                                    padding-bottom: 3px;
                                    padding-right: 5px;
                                    font-size: 1.1rem;
                                }
                                span {
                                    display: -webkit-box;
                                    overflow: hidden;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 1;
                                    word-break: break-word;
                                    font-size: 0.9rem;
                                    font-weight: 700;
                                    color: var(--card-text-color);
                                }
                            }
                        }
                        .content {
                            .presenterItem {
                                .picture {
                                    img {
                                        width: 90px;
                                        height: 60px;
                                        aspect-ratio: 16/9;
                                        background-color: var(--light-clr);
                                        border-radius: var(
                                            --card-border-radius
                                        );
                                        @include media("xl") {
                                            width: 60px;
                                            height: 40px;
                                        }
                                    }
                                }
                                .details {
                                    .name {
                                        h5 {
                                            display: -webkit-box;
                                            overflow: hidden;
                                            margin-bottom: 3px;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 1;
                                            word-break: break-word;
                                            font-size: 1rem;
                                            font-weight: 700;
                                            color: var(--card-text-color);
                                        }
                                    }
                                    .desc {
                                        p {
                                            display: -webkit-box;
                                            overflow: hidden;
                                            margin-bottom: 0;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 2;
                                            word-break: break-word;
                                            font-size: 0.8rem;
                                            font-weight: 400;
                                            color: var(--card-text-color);
                                        }
                                        .showMore {
                                            a {
                                                font-size: 0.8rem;
                                                font-weight: 700;
                                                line-height: normal;
                                                color: var(--primary);
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .registerBtnEnd {
            width: 100%;
            height: 45px;
            border: none !important;
            border-radius: 0 0 var(--card-border-radius)
                var(--card-border-radius) !important;
            font-size: 0.9rem !important;
            &:hover {
                border: none !important;
            }
        }
        &::before {
            pointer-events: none;
        }
    }
    &.hoverView {
        .additional-info {
            position: absolute;
            top: 0;
            right: 100%;
            width: 0;
            height: 0;
            background-color: var(--widget-background-color);
            z-index: var(--z-index-normal);
            box-shadow: 0 3px 9px var(--card-box-shadow-color);
            visibility: hidden;
            opacity: 0;
            transition: display 0s, opacity 0.1s linear;
            overflow: auto;
            scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-background);
            scrollbar-width: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background: var(--scrollbar-background);
            }

            &::-webkit-scrollbar-thumb {
                background: var(--scrollbar-widget-thumb);
            }

            &::-webkit-scrollbar-thumb:hover {
                opacity: 100%;
            }
        }
        &:hover {
            .course-item--card.card-active {
                border-radius: 0 !important;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: var(--z-index-normal);
                    width: 100%;
                    height: 100%;
                    border-color: var(--primary) !important;
                    border-bottom: 3px;
                    border-top: 3px;
                    border-right: 0;
                    border-left: 3px;
                    border-style: solid !important;
                }
                .banner {
                    &--content {
                        border-radius: 0;
                    }
                    .category {
                        z-index: 2;
                    }
                }
                .additional-info {
                    visibility: visible;
                    opacity: 1;
                    height: 100%;
                    width: calc(90%);
                    transition: width 0.2s ease-in;
                    border-color: var(--primary) !important;
                    border-bottom: 3px;
                    border-top: 3px;
                    border-left: 0;
                    border-right: 3px;
                    border-style: solid !important;
                }
            }
        }
        //Details Hovering
        @include media("ech-1") {
            .course-item--card {
                &:before {
                    border-bottom: 0 !important;
                    border-right: 3px !important;
                    border-left: 3px !important;
                    border-style: solid !important;
                }
                .banner {
                    &--content {
                        border-radius: 0;
                    }
                }
                .additional-info {
                    left: auto;
                    right: auto;
                    top: 100%;
                    width: 100% !important;
                    height: 90% !important;
                    border-bottom: 3px !important;
                    border-top: 0 !important;
                    border-left: 3px !important;
                    border-right: 3px !important;
                }
                .fak {
                    transform: rotate(90deg);
                }
            }
        }
        &:nth-child(4n) {
            @include media("ech-3") {
                .course-item--card.card-active {
                    border-radius: 0 !important;

                    &:before {
                        border-bottom: 3px;
                        border-right: 3px;
                        border-left: 0;
                        border-style: solid !important;
                    }
                    .banner {
                        &--content {
                            border-radius: 0;
                        }
                        .category {
                            display: none;
                        }
                    }
                }
                .course-item--card {
                    .additional-info {
                        left: auto !important;
                        right: 100%;
                        border-color: var(--primary) !important;
                        border-bottom: 3px;
                        border-top: 3px;
                        border-left: 3px;
                        border-right: 0;
                    }
                    .left-aligned {
                        .fak {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        &:nth-child(3n) {
            @include media("ech-2") {
                .course-item--card.card-active {
                    border-radius: 0 !important;

                    &:before {
                        border-bottom: 3px;
                        border-right: 3px;
                        border-left: 0;
                        border-style: solid !important;
                    }
                    .banner {
                        &--content {
                            border-radius: 0;
                        }
                        .category {
                            display: none;
                        }
                    }
                }
                .course-item--card {
                    .additional-info {
                        left: auto !important;
                        right: 100%;
                        border-color: var(--primary) !important;
                        border-bottom: 3px;
                        border-top: 3px;
                        border-left: 3px;
                        border-right: 0;
                    }
                    .left-aligned {
                        .fak {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        &:nth-child(4n) {
            @include media("ech-3") {
                .course-item--card.card-active {
                    border-radius: 0 !important;

                    &:before {
                        border-bottom: 3px;
                        border-right: 3px;
                        border-left: 0;
                        border-style: solid !important;
                    }
                    .banner {
                        &--content {
                            border-radius: 0;
                        }
                        .category {
                            display: none;
                        }
                    }
                }
                .course-item--card {
                    .additional-info {
                        left: auto !important;
                        right: 100%;
                        border-color: var(--primary) !important;
                        border-bottom: 3px;
                        border-top: 3px;
                        border-left: 3px;
                        border-right: 0;
                    }
                    .left-aligned {
                        .fak {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        .additional-info {
            position: absolute;
            top: 0;
            left: 100%;
            width: 0;
            height: 0;
            background-color: var(--widget-background-color);
            z-index: var(--z-index-normal);
            box-shadow: 0 3px 9px var(--card-box-shadow-color);
            visibility: hidden;
            opacity: 0;
            transition: display 0s, opacity 0.1s linear;
            overflow: auto;
            scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-background);
            scrollbar-width: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background: var(--scrollbar-background);
            }
            &::-webkit-scrollbar-thumb {
                background: var(--scrollbar-widget-thumb);
            }
            &::-webkit-scrollbar-thumb:hover {
                opacity: 100%;
            }
        }
        .content-active-right {
            visibility: visible;
            opacity: 1;
            height: 100%;
            width: 90%;
            transition: width 0.2s ease-in;
            border-color: var(--primary) !important;
            border: 3px;
            border-left: 0;
            border-style: solid !important;
            .progress-container--normal {
                background-color: var(--card-background-color) !important;
            }
            .margin-16 {
                margin: 16px;
            }
        }
    }

    &.listView {
        .course-item--card {
            .banner {
            }
            .context {
                margin-top: -15px;
                margin-left: 10px;
                margin-right: 10px;
                &--inner {
                    height: 260px;
                    padding-bottom: 0;
                    .header {
                    }
                    .info {
                        height: 90px;
                        padding-top: 0 !important;
                        overflow-x: hidden;
                        overflow-y: auto;
                        scrollbar-color: var(--scrollbar-thumb)
                            var(--scrollbar-background);
                        scrollbar-width: auto;
                        > .row {
                            margin-left: auto !important;
                            margin-right: auto !important;
                            .info--item {
                                padding-left: 0px !important;
                                &.tasls {
                                    padding-bottom: 0 !important;
                                }
                            }
                        }
                        &::-webkit-scrollbar {
                            width: 3px;
                        }
                        &::-webkit-scrollbar-track {
                            background: var(--scrollbar-background);
                        }

                        &::-webkit-scrollbar-thumb {
                            background: var(--scrollbar-widget-thumb);
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            opacity: 100%;
                        }
                    }
                    .desc {
                        height: 90px;
                        .text {
                            -webkit-line-clamp: 3;
                        }
                    }
                    .actions {
                        height: auto;
                    }
                }
            }
            .additional-info {
                height: 340px;
                .users {
                    .user-item--inner {
                        min-height: 45px;
                    }
                }
            }
        }
    }
    @include media("ecg-1") {
        max-width: 100% !important;
        flex: 100% !important;
    }
    @include media("ecg-2") {
        max-width: 33.33% !important;
        flex: 33.33% !important;
    }
}

.left-aligned {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
}
