@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.banner-slider {
    &--content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            pointer-events: none;
            width: inherit;
        }
        .text {
            position: absolute;
            width: 35%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @include media("apbnr-1") {
                width: 50%;
            }
            @include media("maxOldLapTop") {
                width: 70%;
            }
            @include media("mobile") {
                width: 95%;
            }
            .title {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                font-size: 2.5rem;
                font-weight: 700;
                color: var(--banner-title-color);
                margin-bottom: 30px;
                @include media("apbnr-1") {
                    font-size: 2.2rem;
                    margin-bottom: 0px;
                }
                @include media("maxOldLapTop") {
                    font-size: 1.8rem;
                    -webkit-line-clamp: 2;
                }
                @include media("mobile") {
                    font-size: 1.6rem;
                    -webkit-line-clamp: 1;
                }
            }
            .sub-title {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                font-size: 1.2rem;
                line-height: 1.7rem;
                font-weight: 300;
                color: var(--banner-sub-title-color);
                @include media("apbnr-1") {
                    font-size: 1rem;
                }
                @include media("maxOldLapTop") {
                    -webkit-line-clamp: 3;
                }
                @include media("mobile") {
                    -webkit-line-clamp: 1;
                }
            }
        }
    }
}
