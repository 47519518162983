@import "../../../../assets/scss/screen-sizes";
@import "../../../../assets/scss/variables";

@mixin dropDownContent() {
    .dropdown-content {
        min-width: unset;
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        background-color: var(--action-popup-background-color);
        padding: 10px 0;
        border: var(--action-popup-border-width) solid
            var(--action-popup-border-color);
        border-radius: var(--action-popup-border-radius);
        box-shadow: 0 1px 9px var(--action-popup-shadow-color);
        list-style: none;
        li {
            a {
                display: block;
                padding: 5px 15px;
                color: var(--action-popup-text-opt-color);
                background-color: var(--action-popup-background-opt-color);
                font-size: 12px;
                font-weight: 700;
                height: 30px !important;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 170px;
                text-decoration: none;
                i {
                    width: 19px;
                    padding-bottom: 3px;
                    font-size: 16px;
                    margin-right: 5px;
                    display: inline-block;
                    vertical-align: middle;
                    font-weight: 400;
                }
                &:hover {
                    color: var(--action-popup-text-opt-hover-color);
                    background-color: var(
                        --action-popup-background-opt-hover-color
                    );
                }
            }

            &.disabled {
                a {
                    color: var(--card-second-text-color);
                }
            }
        }
    }
    @content;
}

.action-dropdown {
    &.dropdown {
        .dropdown-toggle {
            &:after {
                display: none;
            }

            &:hover {
                & ~ .dropdown-menu {
                    display: block;
                }
            }
            i {
                font-size: 20px;
            }

            &.withDefaultText {
                height: 32px;
                i {
                    font-size: 14px;
                }
            }
        }

        .dropdown-menu {
            &:hover {
                display: block;
            }
            top: 31px;
            right: 0;
            left: auto;
            text-align: left;
            min-width: 170px;
            padding-top: 10px;
            margin-top: 0;
            background-color: unset;
            border: none;
        }

        @include dropDownContent;
    }

    &.language-action-pop-up {
        .dropdown-toggle {
            &.withDefaultText {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 36px;
                background-color: var(--secondary-button-background-color);
                border: none;
                border-radius: var(--secondary-button-border-radius);
                box-shadow: 0 3px 9px var(--secondary-button-box-shadow-color);

                @include media("mobile") {
                    padding: 0 7px !important;
                }

                &::after {
                    margin-left: 10px;
                    font-family: "Font Awesome Kit";
                    font-size: 13px;
                    font-weight: 700;
                    color: var(--primary);
                    content: "\e018";
                    border-top: none;
                    display: block;
                }

                i {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .dropdown-content {
            li a i {
                width: 15px;
                height: 15px;
            }
        }
    }
}

.dropdown-popover-action {
    z-index: 999999 !important;
    right: 10px !important;
    border: none !important;
    width: fit-content !important;
    border-radius: var(--action-popup-border-radius);
    min-width: 170px;
    .arrow {
        display: none !important;
    }

    @include dropDownContent;
}