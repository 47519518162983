@import "../../../../assets/scss/screen-sizes";

.badge-avatar {
    width: var(--badge-size);
    height: var(--badge-size);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50%));
    z-index: 0;
    .badge-rotate {
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--badge-size);
        height: var(--badge-size);
        transform: translate(-50%, calc(-50%));
        z-index: 2;
    }

    &--container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50%));
        width: 100%;
        height: 100%;
        > div {
            span {
                font-style: normal !important;
                color: white;
                font-weight: 700;
                font-size: 15px;
            }
        }
    }
    &--background {
        position: absolute;
        width: var(--badge-size);
        height: var(--badge-size);
        border-radius: 50%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
    }
}
