@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";
:root {
    --container-category-background-color: var(--primary);
}
.container-item {
    margin-bottom: 30px;
    &--card {
        cursor: pointer;
        .banner {
            &--content {
                position: relative;
                overflow: hidden;
                content: "";
                aspect-ratio: 16/9;
                border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                .presenter {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    width: 100px;
                    aspect-ratio: 16/9;
                    background-color: var(--light-clr);
                    border-radius: var(--card-border-radius);
                    i {
                        width: 100%;
                        aspect-ratio: 16/9;
                        content: "";
                        display: block;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }
            .category {
                position: absolute;
                top: 90px;
                left: -10px;
                .content {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    height: 28px;
                    padding: 0 10px;
                    background-color: var(--container-category-background-color);
                    border-radius: 3px;
                    span {
                        display: -webkit-box;
                        min-width: fit-content;
                        max-width: inherit;
                        overflow: hidden;
                        padding-top: 2px;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        font-size: 0.8rem;
                        font-weight: 700;
                        color: var(--light-clr);
                        word-break: break-all;
                    }
                    &:after {
                        content: "\e04a";
                        font-family: "Font Awesome Kit";
                        transform: rotate(180deg);
                        font-size: 11px;
                        position: absolute;
                        left: 0.5px;
                        top: -7.5px;
                        color: var(--container-category-background-color);
                    }
                }
            }
        }
        .context {
            z-index: 0;
            margin-top: -20px;
            margin-left: 20px;
            margin-right: 20px;
            &--inner {
                padding: 15px;
                background-color: var(--card-background-color);
                border-radius: var(--card-border-radius);
                .header {
                    display: flex;
                    height: 45px;
                    .title {
                        h3 {
                            display: -webkit-box;
                            width: fit-content;
                            font-size: 1.1rem;
                            font-weight: 700;
                            color: var(--card-text-color);
                            margin-bottom: 0;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            cursor: pointer;
                        }
                    }
                }
                .desc {
                    height: 120px;
                    .text {
                        display: -webkit-box;
                        width: fit-content;
                        margin-bottom: 0;
                        font-size: 0.9rem;
                        font-weight: 400;
                        line-height: 1.2rem;
                        color: var(--card-text-color);
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 6;
                        overflow: hidden;
                    }
                }
                .actions {
                    display: flex;
                    height: 40px;
                    .showMore {
                        a {
                            font-size: 0.8rem;
                            font-weight: 700;
                            line-height: normal;
                            color: var(--primary);
                            text-decoration: none;
                        }
                    }
                    .registerBtn {
                        margin-right: -5px;
                        margin-left: auto;
                        padding-left: 8px;
                        .btn {
                            height: 34px;
                            padding: 0 35px !important;
                            font-size: 0.8rem !important;
                        }
                    }
                }
            }
        }

        .category-border {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8px;
            border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
        }
    }
    @include media("ecg-1") {
        max-width: 100% !important;
        flex: 100% !important;
    }
    @include media("ecg-2") {
        max-width: 33.33% !important;
        flex: 33.33% !important;
    }
}
