@import "../../../../../AppModule/assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.footer {
    background-image: url(../images/footer-backgount-image.png);
    &--container {
        .content {
            display: flex;
            padding: 80px 0 100px 0;

            @include media("fgc-6") {
                flex-wrap: wrap;
                padding: 30px 0 30px 0;
            }
            .adress {
                color: var(--footer-text-color);
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.8rem;
            }
            .logo-container {
                width: 25%;
                padding-right: 50px;
                @include media("ecg-2") {
                    width: 20%;
                    padding-right: 20px;
                }
                @include media("fgc-6") {
                    width: 100%;
                    padding: 15px;
                }
                .logo {
                    width: auto;
                    height: 64px;
                }
                .about {
                    margin-top: 30px;
                    margin-bottom: 0;
                    color: var(--footer-text-color);
                    font-family: var(--font-family);
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.8rem;
                    @include media("fgc-5") {
                        margin-top: 10px;
                    }
                }
            }
            .footer-col {
                padding: 15px 30px;
                &:last-child {
                    padding-right: 0;
                }
                &--header {
                    margin-bottom: 28px;
                    h4 {
                        font-size: 1.6rem;
                        font-weight: 500;
                        color: var(--footer-header-text-color);
                    }
                }
                &--content {
                    .adress {
                        margin-bottom: 2px;
                    }
                    .item {
                        margin-bottom: 12px;
                        &:hover {
                            text-decoration: underline;
                            text-decoration-color: var(--footer-text-color);
                        }
                        a {
                            font-size: 1rem;
                            font-weight: 600;
                            text-decoration: none;
                            color: var(--footer-text-color);
                        }
                        &.socials {
                            > .div {
                                align-items: center;
                            }
                            .social-item {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-right: 12px;

                                a {
                                    img {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                                &.facebook {
                                    padding-bottom: 4px;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
                &.col1,
                &.col2 {
                    @include media("fgc-6") {
                        width: auto;
                        padding: 15px;
                        margin-right: unset !important;
                        margin-left: unset !important;
                    }
                }
                &.col1,
                &.col2,
                &.col3,
                &.col4,
                &.col5 {
                    @include media("fgc-6") {
                        width: auto;
                        padding: 15px;
                        margin-right: unset !important;
                        margin-left: unset !important;
                    }
                    @include media("ecg-2") {
                        width: 20%;
                        padding: 15px 15px;
                    }
                }
                &:last-child {
                    @include media("fgc-6") {
                        width: auto;
                        padding: 15px;
                        margin-right: unset !important;
                        margin-left: unset !important;
                    }
                    @include media("ecg-2") {
                        width: 20%;
                    }
                }
            }
        }
        .copyright {
            border-top: 2px solid var(--footer-divider-color);
            padding: 20px 0;
            @include media("sm") {
                padding: 10px 0;
            }
            &--content {
                display: flex;
                @include media("fgc-5") {
                    flex-wrap: wrap;
                }
                @include media("sm") {
                    justify-content: center;
                }
                .label {
                    padding-right: 10px;
                    span {
                        font-size: 1rem;
                        font-weight: 400;
                        color: var(--footer-text-color);
                        @include media("fgc-5") {
                            font-size: 0.8rem;
                        }
                        @include media("sm") {
                            padding-right: 0;
                        }
                    }
                    @include media("sm") {
                        padding-right: 0;
                    }
                }
                .links {
                    display: flex;
                    align-items: center;
                    margin-right: 0;
                    margin-left: auto;
                    @include media("sm") {
                        margin-right: auto;
                        margin-left: auto;
                    }
                    .link-item {
                        a {
                            padding-left: 20px;
                            font-size: 1rem;
                            font-weight: 400;
                            text-decoration: none;
                            color: var(--footer-text-color);
                            @include media("fgc-5") {
                                font-size: 0.8rem;
                            }
                        }
                    }
                    .divider {
                        padding: 0 15px;
                        color: var(--footer-text-color);
                    }
                }
            }
        }
    }
}
