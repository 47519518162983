@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: var(--navigation-background-color);
    box-shadow: 0px 2px 19px 1px var(--header-box-shadow-color);
    .header--container {
        display: flex;
        align-items: center;
        height: 70px;
        @include media("mobile") {
            height: 50px;
        }
        @include media("mini") {
            height: 40px;
        }
        .navbar {
            width: 100%;
            padding: 0;
            @include media("expandToggleMenu") {
                justify-content: unset;
            }
        }
        .logo-container {
            padding-right: 30px;
            a {
                img {
                    height: 40px;
                    width: 100%;
                    @include media("mobile") {
                        max-width: 90px;
                        height: 100%;
                    }
                    @include media("sm") {
                        max-width: 90px;
                        height: 100%;
                    }
                    @include media("mini") {
                        max-width: 90px;
                        height: 15px;
                    }
                }
            }
            @include media("sm") {
                margin-right: 0;
                padding-right: 10px;
            }
        }
        .navigation-menu {
            justify-content: space-between;
            @include media("expandToggleMenu") {
                position: absolute;
                right: 0px;
                top: calc(100% + 10px);
                min-width: 150px;
                animation: growOut 100ms ease-in-out;
                order: 3;
                background-color: var(--navigation-background-color);
                &.show {
                    @keyframes growOut {
                        0% {
                            opacity: 0;
                        }
                        80% {
                            opacity: 0.5;
                        }
                        100% {
                            opacity: 1;
                        }
                    }

                    border-radius: 0 0 5px 5px;
                    padding: 15px;
                    box-shadow: 0px 11px 19px 1px var(--header-box-shadow-color);
                }
                .searchBarCol {
                    border-top: 1px solid var(--header-divider-color);
                    padding-top: 15px;
                    padding-right: 0 !important;
                    .header-search {
                        width: 100% !important;
                        .input-group-text {
                            padding-left: 0;
                        }
                    }
                }
            }
            @include media("mobile") {
                top: calc(100% + 4px);
            }
            @include media("mini") {
                top: calc(97%);
            }

            &--inner {
                .nav-item {
                    padding-right: 35px;
                    @include media("apphdr-1") {
                        padding-right: 30px;
                    }
                    @include media("expandToggleMenu") {
                        padding-right: 0;
                        &:first-child {
                            a {
                                padding-top: 0;
                            }
                        }
                        a {
                            display: block;
                            width: 100%;
                            padding: 10px 0px;
                            span {
                                font-weight: 400 !important;
                            }
                        }
                    }
                    a {
                        text-decoration: none;
                        cursor: pointer;
                        &.active {
                            span {
                                color: var(--navigation-item-text-color--active);
                            }
                        }
                        span {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-overflow: ellipsis;
                            max-width: 130px;
                            overflow: hidden;
                            color: #444;
                            color: var(--navigation-item-text-color);
                            font-size: 1rem;
                            font-weight: 500;
                            word-break: break-word;
                            &:hover {
                                color: var(--navigation-item-text-color--hover);
                            }
                            @include media("expandToggleMenu") {
                                max-width: unset;
                            }
                        }
                    }
                }
            }
            .searchBarCol {
                .header-search {
                    width: 15vw;
                }
            }
        }
        .navbar-toggler {
            padding-right: 0;
            @include media("expandToggleMenu") {
                order: 3;
                margin-left: 5px;
                border: unset;

                i {
                    font-size: 1.3rem;
                    color: var(--navigation-item-text-color);
                }
            }
            @include media("sm") {
                padding-left: 10px;
                i {
                    font-size: 1rem;
                }
            }
            @include media("mini") {
                i {
                    font-size: 0.8rem;
                }
            }
        }

        .right-container {
            order: 2;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-right: 0;
            margin-left: auto;
            .signInCol {
                a {
                    font-size: 0.9rem;
                    font-weight: 900;
                    color: var(--navigation-item-text-color);
                    text-decoration: none;
                    @include media("mobile") {
                        font-size: 0.8rem;
                    }
                    @include media("mini") {
                        font-size: 0.6rem !important;
                    }
                }
            }

            .registerBtn {
                height: 40px;
                padding: 0 30px !important;
                @include media("mobile") {
                    height: 36px;
                    font-size: 0.8rem !important;
                    padding: 0 10px !important;
                }
                @include media("mini") {
                    height: 25px;
                    font-size: 0.6rem !important;
                }
            }

            .hidden-language-button {
                display: none !important;
            }
            .language-dropdown {
                
                @include media("mobile") {
                    margin-bottom: 8px;
                }
            }
        }
    }
}
