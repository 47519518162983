@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.p-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include media("pc-1") {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1450px;
    }
    @include media("pc-2") {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1600px;
    }
}
