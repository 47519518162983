@import "../../../../assets/scss/variables";
// @Erfan to check
// @import "../../../../assets/scss/screens-sizes";
// @import "../../../AppUserSelector/assets/scss/style.scss";

.user-list-modal {
    .modal-dialog {
        .modal-content {
            .modal-body {
                .content {
                    height: 250px;
                    overflow: auto;
                    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-background);
                    scrollbar-width: auto;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        background: var(--scrollbar-background);
                    }

                    &::-webkit-scrollbar-thumb {
                        background: var(--scrollbar-widget-thumb);
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        opacity: 100%;
                    }
                }
            }
        }
    }
}
