@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/screen-sizes.scss";

.header-search {
    &--content {
        height: 36px;
        overflow: hidden;
        background-color: var(--input-background-color);
        border: var(--input-border-width) solid var(--input-border-color) !important;
        border-width: var(--input-border-width);
        border-radius: var(--input-border-radius);

        &.icon-left {
            flex-direction: row-reverse;

            .form-control {
                padding-left: 4px;
            }
        }

        border: var(--input-border-width) solid var(--input-border-color) !important;
        border-radius: var(--input-border-radius);
        .form-control {
            padding-top: 6px;
            border: none !important;
            font-size: 0.9rem;
            line-height: 0.9rem;
            font-weight: 400;
            color: var(--input-text-color);
            outline: none;
            &::placeholder {
                font-size: 0.9rem;
                line-height: 0.9rem;
                color: var(--input-placeholder-text-color);
            }
            &:focus,
            &:active {
                box-shadow: unset;
            }
        }

        .input-group-text {
            padding: 4px 8px;
            padding-bottom: 7px;
            color: var(--input-text-color) !important;
            background-color: var(--input-background-color) !important;
            border: none;
            .searchIcon {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
        //clears the 'x'
        input[type="search"]::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }
        input[type="search"]::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }
        ///* clears the 'X' from Chrome */
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
            display: none;
        }
    }
}
