// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Use abbreviations as follow
//  background => bg
//  foreground => fg
//  button => btn
//  color => clr

//custom colors

:root {
    //font
    --font-family-title: "grotesque";
    --font-family-body: "grotesque";
    --font-family-button: "grotesque";

    // global colors
    --primary: #0052cc;
    --primary-10: #0052cc10;
    --primary-15: #0052cc15;
    --primary-20: #0052cc20;
    --primary-30: #0052cc30;
    --primary-40: #0052cc40;
    --primary-50: #0052cc50;
    --primary-60: #0052cc60;
    --primary-70: #0052cc70;
    --primary-80: #0052cc80;
    --primary-90: #0052cc90;
    --secondary: #444;
    --secondary-10: #44444410;
    --secondary-15: #44444415;
    --secondary-20: #44444420;
    --secondary-30: #44444430;
    --secondary-40: #44444440;
    --secondary-50: #44444450;
    --secondary-60: #44444460;
    --secondary-70: #44444470;
    --secondary-80: #44444480;
    --secondary-90: #44444490;
    --danger: #ff4365; // invalid messages and toast,
    --success: #2cda9d; // success toast,
    --info: #7ebde9; // attendee card, if user is speaker (button/badge with skyblue)
    --body-background-color: #f7f3f5;
    --iframe-body-background-color: #edededcb;

    // exam status color
    --question-current: #0052cc;
    --question-upcoming: #44444480;
    --question-attempted: #2cda9d50;
    --question-attempted-not: #ff336570;
    --question-correct: #2cda9d;
    --question-incorrect: #ff4365;

    // widget/card variables
    --card-background-color: #fff;
    --card-text-color: #444;
    --card-text-color-80: #444444d0;
    --card-second-text-color: #44444470;
    --card-border-radius: 5px;
    --card-box-shadow-color: #44444430;
    --card-divider-color: #44444420;
    --card-compact-heading-color: #444444;
    --widget-sponsor-background-color: #e6eefa;
    --widget-background-color: #e6eefa;
    --card-border-color: #a0b2c080;
    --card-selected-bg-color: #b7c7e0;

    //card scrollbar

    --card-scrollbar-track: #a0b2c0;
    --card-scrollbar-thumb: #444444;

    //widget/background-music-player variables
    --background-music-player-background-color: #0052cc15;
    --background-music-player-bar-color: #0052cc;
    --background-music-player-volume-bar-color: #0052cc;

    // tabs
    --tab-border-radius: 5px;
    --tab-background-color: #fff;
    --tab-text-color: #444;
    --tab-box-shadow-color: #44444430;
    --tab-background-color--active: #e6eefa;
    --tab-text-color--active: #0052cc;
    --tab-box-shadow-color--active: #44444430;

    // inputs
    --input-background-color: #fff;
    --input-border-color: #44444470;
    --input-border-width: 1px;
    --input-border-radius: 5px;
    --input-text-color: #444; //  inherit secondary
    --input-placeholder-text-color: #a7a7a7;
    --input-background-color--focus: #fcfcfc;
    --input-border-color--focus: #a7a7a7;
    --input-border-width--focus: 2px;
    --input-background-color--disable: #fcfcfc;
    --input-border-color--disable: #a7a7a7;

    // select / dropdown
    --select-background-color: #fff;
    --select-text-color: #444;
    --select-border-color: #44444420;
    --select-box-shadow-color: #44444430;
    --select-border-width: 1px;
    --select-border-radius: 5px;
    --select-option-background-color: #fff;
    --select-option-text-color: #444;
    --select-option-background-color--hover: #0052cc10;
    --select-option-text-color--hover: #0052cc;

    // buttons
    --primary-button-background-color: #0052cc; // inherit primary color
    --primary-button-text-color: #fff;
    --primary-button-border-color: #fff;
    --primary-button-border-width: 1px;
    --primary-button-border-radius: 5px;
    --primary-button-box-shadow-color: #44444430;
    --primary-button-background-color--disable: #444;
    --primary-button-text-color--disable: #fff;
    --primary-button-border-color--disable: #fff;
    --primary-button-background-color--active: #0052cc;
    --primary-button-text-color--active: #fff;
    --primary-button-border-color--active: #fff;
    --primary-button-background-color--hover: #0052cc;
    --primary-button-text-color--hover: #fff;
    --primary-button-border-color--hover: #fff;
    --secondary-button-background-color: #fff; // inherit primary color
    --secondary-button-text-color: #444;
    --secondary-button-border-color: #fff;
    --secondary-button-border-width: 1px;
    --secondary-button-border-radius: 5px;
    --secondary-button-box-shadow-color: #44444430;
    --secondary-button-background-color--disable: #444;
    --secondary-button-text-color--disable: #fff;
    --secondary-button-border-color--disable: #fff;
    --secondary-button-background-color--active: #fff;
    --secondary-button-text-color--active: #444;
    --secondary-button-border-color--active: #fff;
    --secondary-button-background-color--hover: #fff;
    --secondary-button-text-color--hover: #444;
    --secondary-button-border-color--hover: #fff;
    --danger-button-background-color: #ff4365;
    --danger-button-text-color: #fff;
    --danger-button-border-color: #ff4365;
    --danger-button-border-width: 1px;
    --danger-button-border-radius: 5px;
    --danger-button-box-shadow-color: #fff0;
    --danger-button-background-color--disable: #444;
    --danger-button-text-color--disable: #fff;
    --danger-button-border-color--disable: #fff;
    --danger-button-background-color--active: #ff4365;
    --danger-button-text-color--active: #fff;
    --danger-button-border-color--active: #ff4365;
    --danger-button-background-color--hover: #ff4365;
    --danger-button-text-color--hover: #fff;
    --danger-button-border-color--hover: #ff4365;
    --success-button-border-radius: 5px;

    // navigation
    --navigation-collapsed-width: 75px;
    --navigation-border-radius: 5px;
    --navigation-background-color: #fff;
    --navigation-item-background-color: #fff;
    --navigation-item-text-color: #444;
    --navigation-item-icon-color: #444;
    --navigation-item-divider-color: #44444420;
    --navigation-item-background-color--active: #e5effc;
    --navigation-item-text-color--active: #0052cc;
    --navigation-item-icon-color--active: #0052cc;
    --navigation-item-background-color--hover: #e5effc;
    --navigation-item-text-color--hover: #0052cc;
    --navigation-item-icon-color--hover: #0052cc;

    // global search
    --global-search-border-width: 1px;
    --global-search-border-radius: 5px;
    --global-search-box-shadow-color: #44444430;
    --global-serch-background-color: #ffffff;
    --global-search-border-color: #44444420;
    --global-search-placeholder-text-color: #a7a7a7;

    // modal
    --modal-background-color: #fff;
    --modal-backdrop-background-color: #0052cc90;
    --modal-text-color: #444;
    --modal-border-radius: 5px;

    // ag-grid / table
    --table-header-background-color: #fff;
    --table-header-background-color--hover: #0052cc20;
    --table-divider-color: #0052cc30;
    --table-row-odd-background-color: #fff;
    --table-row-even-background-color: #fff;
    --table-row-text-color: #444;
    --table-bulk-menu-background-color: #e6eefa;

    // Action Pop-Up (3 dots menu & other similar action pop-up)
    --action-popup-background-color: #fff;
    --action-popup-border-color: #44444420;
    --action-popup-shadow-color: #44444430;
    --action-popup-border-radius: 0;
    --action-popup-border-width: 0;
    --action-popup-background-opt-color: #ffffff;
    --action-popup-background-opt-hover-color: #0052cc10;
    --action-popup-text-opt-color: #444444;
    --action-popup-text-opt-hover-color: #0052cc;

    // toast
    --toast-background-color: #fff;
    --toast-border-radius: 5px;

    // default images
    --default-card-image-background: #44444410;
    --default-container-card-background: #44444410;
    --default-event-card-background: #44444410;
    --default-session-card-background: #44444410;
    --default-avatar: ""; // ?
    --default-sponsor-border-radius: 5px;

    // tags
    --tag-border-radius: 17px;
    --tag-background-clr: #e6eefa;

    //chips
    --chips-background-color: #fff;
    --chips-border-color: var(--primary);
    --chips-text-color: var(--primary);
    --chips-background-color-selected: var(--primary-20);
    --chips-border-color-selected: #fff;
    --chips-text-color-selected: var(--primary);

    // PIP Player
    --pip-player-border-radius: 10px;

    // Image Drop Area
    --image-drop-area-border-style: "solid"; // ?
    --image-drop-area-border-width: 1px; // ?

    //static variable
    --light-clr: #fff;
    --circle-border-radius: 50%;
    --doc-icon-border-radius: 4px;
    --folder-icon-color: #59cafc;
    --google-drive-link-icon-color: #44444470;
    --folder-banner-color: #59cafc60;
    --checkbox-border-radius: 1px;
    --scrollbar-background: #44444410;
    --message-in-box-fg-clr: #eaf6ff;
    --message-chat-one-to-one-header: #e6eefa;

    //task and exam overview
    --tree-lines-clr: #8d9ba7e1;

    //future all
    --docx-i-bg-clr: #3b6cc7;
    --pdf-i-bg-clr: #c72424;
    --pdf-i-bg-clr-20: #c7242450;
    --pptx-i-bg-clr: #f88703;
    --xls-i-bg-clr: #288e5d;
    --file-icon-bg-clr: #fff;
    --live-badge-bg-clr: #2cda9d;
    --scrollbar-thumb: var(--primary);
    --countdown-timer-text-color: #fff;
    --online-badge-bg-clr: #2cda9d;
    --user-badge-default: #e2e2e2;
    --user-badge-border-radius: 3px;
    //future // video call colors
    --video-call-bg-clr: #000;
    --video-call-grey-bg-clr: #444;
    --video-call-grey-bg-clr-opacity: #44444410;
    --video-call-transparent-bg-clr: transparent;
    --video-call-fg-clr: #fff;
    --dark-btn-bg-clr: #ffffff10;
    --dark-btn-fg-clr: #fff;
    --dark-btn-active-bg-clr: #fff;
    --dark-btn-active-fg-clr: #232323;
    --video-sc-thumb-bg-clr: #a0b2c0;
    --end-call-btn-bg-clr: #eb5757;
    --start-call-btn-bg-clr: #2cda9d;
    --members-box-bg-clr: #fff;
    --light-bg-clr-scrollbar: #fff;
    --sit-btn-bg-clr: #eb5757;
    --sit-btn-fg-clr: #fff;
    --non-video-bg-clr: #444;
    --overlay-option-bg-clr: #00000090;
    --overlay-white-bg-clr: #ffffff90;
    // miscellaneous
    --ask-speaker-widget-background-color: #e6eefa;
    --field-bg-clr: #e6eefa;

    // progress bar
    --progress-bar-text-clr: #444;
    --progress-bar-border-clr: #e6eefa;

    //e-learning-course
    --course-tag-bg-clr: #e6eefa;
    --course-text-duration-clr: #a0b2c0;
    --course-text-description-clr: #a0b2c0;
    --course-label-progress-bar-clr: #051515;
    --course-card-des-clr: #a0b2c0;
    --course-card-lesson-clr: #e6eefa;
    --task-card-des-clr: #a0b2c0;
    --task-div-clr: #a0b2c0;
    --task-header-clr: #a0b2c0;
    --task-border-clr: rgba(160, 178, 192, 0.3);
    --task-card-bg-clr: #e6eefa;

    // task status
    --not-start-bg-clr: #00000000;
    --started-bg-clr: #87bcde30;
    --completed-bg-clr: var(--primary-10);
    --expired-bg-clr: #fbbd0030;
    --not-start-clr: var(--secondary);
    --total-clr: #007bff;
    --started-clr: #87bcde;
    --completed-clr: var(--primary);
    --expired-clr: #fbbd00;
    --failed-clr: #ff4365;
    --passed-clr: #2cda9d;

    //static variables
    --online-indicator-bg-clr: #2cda9d;
    --offline-indicator-bg-clr: #cdcdcd;
    --status-indicator-border-clr: #fff;
    --status-indicator-border-width: 3px;
    --status-indicator-small-border-width: 2px;

    //charts variables
    --chart-fill-color: #0052cc;
    --chart-fill-highlight-color: #0052cc90;
    --chart-label-txt-color: #666;
    --line-chart-primary-color: #0052cc;
    --line-chart-secondary-color: #f88703;

    // calendar variables
    --calendar-border-clr: #a0b2c030;
    --calendar-bg-clr: white;
    --calendar-border-none-clr: white;
    --calendar-fill-bg-clr-1: var(--primary);
    --calendar-fill-bg-clr-2: var(--primary);
    --calendar-fill-bg-clr-3: var(--primary);
    --calendar-height-header: 130px;
    --calendar-height-row: 50px;

    --calendar-width: 100%;
    --calendar-height: 645px;
    --calendar-header-left-width: 310px;
    --calendar-col-width: 40px;
    --calendar-border-radius: 17px;

    // badge avt
    --badge-size: 156px;

    // document viewer
    --document-hub-viewer-background-color: rgb(255, 255, 255);
    --document-hub-shadow-color: #44444415;
    --pdf-controls-background-color: rgba(43, 41, 41, 0.9);
    --controls-background-color: rgba(43, 41, 41, 0.9);

    //comment var
    --comment-background-color: var(--input-background-color);
    --comment-border-radius: var(--input-border-radius);

    //Public Page
    --scrollbar-thumb: var(--primary);
    --scrollbar-body-thumb: var(--secondary-50);
    --scrollbar-widget-thumb: var(--primary);

    //header
    --header-box-shadow-color: #44444430;

    //banner
    --banner-title-color: #ffffff;
    --banner-sub-title-color: #ffffff;

    //footer
    --footer-background-color: #f7f3f5;
    --footer-header-text-color: #ffffff;
    --footer-text-color: #ffffff;
    --footer-divider-color: #ffffff;

    //section
    --section-header-title-color: var(--secondary);
    --section-header-subtitle-color: var(--secondary);
    --event-overview-background-color: #f7f3f5;

    // Uploaded file
    --uploaded-file-background-color: #a0b2c01a;
    --uploaded-file-shadow-color: #9ba7ba40;

    // chapter border radius
    --full-screen-chapter-radius: 5px;

    // default box shadow
    --box-shadow: 0px 3px 9px rgba(155, 167, 186, 0.25);

    // news-feed post-type & post-sub-type colors
    --default-bg-clr: var(--primary-50);
    --document-bg-clr: var(--pdf-i-bg-clr-20);
    --newsfeed-post-title-color: #444444;
    --conference-bg-clr: #9370db90;
    --session-bg-clr: #b1cbbb;
    --course-bg-clr: #ff69b480;
    --exam-bg-clr: #00ced180;
    --task-bg-clr: #ff572280;
    --exhibitor-bg-clr: #87bdd8;
    --video-gallery-bg-clr: #cd853f80;
    --container-bg-clr: #e6eefa;
    --info-page-bg-clr: #8b24c74f;

    // Z-Index
    --z-index-auto: auto;
    --z-index-background: -1;
    --z-index-none: 0;
    --z-index-normal: 1;
    --z-index-border: 2;
    --z-index-header: 100;
    --z-index-button: 300;
    --z-index-button-icon: 350;
    --z-index-modal-backdrop: 990;
    --z-index-modal: 1000;
    --z-index-banner: 1050;

    --z-index-popover: 999999; // lucas to check later
    --z-index-popup: 1055;
    --z-index-tooltip: 1070;
    --z-index-loader: 2000;

    --z-index-dropdown: 5000;

    --z-index-message: 10500;

    --z-index-submenu-items: 99;
    --z-index-submenu-main: 9;

    --z-index-front: 900000;
    --z-index-sidebar: 900050;
    --z-index-gpt-banner: 900500;
    --z-index-overlay: 9001000;
    --z-index-toast: 1000000;
    --z-index-important: 1000000000 !important;
}
