@import "./variables";
@import "./screen-sizes";
@import "./fonts";
@import "../../../config/css/kit-upload.css";

body {
    font-family: var(--font-family-body);
    background-color: var(--body-background-color);
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-background);
    scrollbar-width: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: var(--scrollbar-background);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-body-thumb);
    }

    &::-webkit-scrollbar-thumb:hover {
        opacity: 100%;
    }
}

//buttons
.btn {
    &.btn-primary {
    }
}
