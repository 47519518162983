@font-face {
    font-family: "Font Awesome Kit";
    font-style: normal;
    src: url("../../../config/fonts/kit-upload.woff2") format("woff2");
}

@font-face {
    font-family: grotesque;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/LabGrotesque-Light.ttf");
}

@font-face {
    font-family: grotesque;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/LabGrotesque-Regular.ttf");
}

@font-face {
    font-family: grotesque;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/LabGrotesque-Medium.ttf");
}

@font-face {
    font-family: grotesque;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/LabGrotesque-Bold.ttf");
}

@font-face {
    font-family: grotesque;
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/LabGrotesque-Black.ttf");
}

@font-face {
    font-family: grotesque;
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/LabGrotesque-Black.ttf");
}

@font-face {
    font-family: creditsuisse;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/CreditSuisseTitles.woff");
}

@font-face {
    font-family: creditsuisse;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/CreditSuisseBody.ttf");
}

@font-face {
    font-family: creditsuisse;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/CreditSuisseButton.ttf");
}

@font-face {
    font-family: ruagsimplon;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/RUAGSimplon-Medium.ttf");
}

@font-face {
    font-family: ruagsimplon;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/RUAGSimplon-Medium.ttf");
}

@font-face {
    font-family: ruagsimplon;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/RUAGSimplon-Medium.ttf");
}

@font-face {
    font-family: interstate;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/InterstateButton.ttf");
}

@font-face {
    font-family: interstate;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/InterstateTitle.ttf");
}

@font-face {
    font-family: interstate;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/InterstateBody.ttf");
}

@font-face {
    font-family: roboto;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Roboto-Bold.ttf");
}

@font-face {
    font-family: roboto;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Roboto-Light.ttf");
}

@font-face {
    font-family: open-sans;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/OpenSans-Light.ttf");
}

@font-face {
    font-family: open-sans;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/OpenSans-Medium.ttf");
}

@font-face {
    font-family: open-sans;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: open-sans;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/OpenSans-Bold.ttf");
}

@font-face {
    font-family: helvetica;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Helvetica-Light.ttf");
}

@font-face {
    font-family: helvetica;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Helvetica-Regular.ttf");
}

@font-face {
    font-family: helvetica;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Helvetica-Regular.ttf");
}

@font-face {
    font-family: helvetica;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Helvetica-Bold.ttf");
}

@font-face {
    font-family: helveticaNeue;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/HelveticaNeue-Bold.otf");
}

@font-face {
    font-family: helveticaNeue;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/HelveticaNeue-Regular.otf");
}

@font-face {
    font-family: helveticaNeue;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/HelveticaNeue-Light.otf");
}

@font-face {
    font-family: carlsberg;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/CarlsbergSansLight.ttf");
}

@font-face {
    font-family: carlsberg;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/CarlsbergSansBold.ttf");
}

@font-face {
    font-family: carlsberg;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/CarlsbergSansBlack.ttf");
}

@font-face {
    font-family: 'voltamodern';
    src: url('../fonts/VoltaModernDisplay-75Bold-Web.woff2') format('woff2'),
        url('../fonts/VoltaModernDisplay-75Bold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'voltamodern';
    src: url('../fonts/VoltaModernDisplay-55Roman-Web.woff2') format('woff2'),
        url('../fonts/VoltaModernDisplay-55Roman-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'voltamodern';
    src: url('../fonts/VoltaModernDisplay-35Thin-Web.woff2') format('woff2'),
        url('../fonts/VoltaModernDisplay-35Thin-Web.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: sourcesanspro;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/SourceSansPro-Light.ttf");
}

@font-face {
    font-family: sourcesanspro;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: sourcesanspro;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/SourceSansPro-Bold.ttf");
}