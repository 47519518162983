@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.event-overview {
    background-color: var(--default-overview-background-color);
    .p-container {
        padding: 10px;
    }
    &--items {
        padding: 20px 0;
        @include media("xll") {
            padding: 20px 20px;
        }
    }
    .showMoreItems {
        text-align: center;
        a {
            font-size: 1rem;
            font-weight: 500;
            text-decoration: none;
            color: var(--primary);
        }
    }
}
