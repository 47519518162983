@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.card {
    color: var(--card-text-color);
    background-color: var(--card-background-color) !important;
    border: unset !important;
    border-radius: var(--card-border-radius) !important;
    box-shadow: 0 3px 9px var(--card-box-shadow-color);
}
