@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.video-frame {
    display: block;
    overflow: hidden;
}
