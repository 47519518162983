@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/screen-sizes.scss";

.public-container {
    .event-overview {
        position: relative;
        background-color: var(--default-overview-background-color);
        z-index: 2;
        &::before {
            position: absolute;
            display: block;
            content: " ";
            left: 0;
            bottom: 0;
            z-index: -1;
            width: 9vw;
            height: 18vw;
            background-image: url("../../../../assets/images/vectors/vector10.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        &::after {
            position: absolute;
            display: block;
            content: " ";
            left: 0;
            z-index: -1;
            bottom: 300px;
            width: 11vw;
            height: 10vw;
            background-image: url("../../../../assets/images/vectors/vector11.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .video-overview {
        position: relative;
        z-index: 1;
        background-color: #eef8fe;
        &::before {
            position: absolute;
            display: block;
            content: " ";
            right: 0;
            top: 30%;
            z-index: -1;
            width: 9vw;
            height: 18vw;
            background-image: url("../../../../assets/images/vectors/vector12.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        &::after {
            position: absolute;
            display: block;
            content: " ";
            right: 0;
            z-index: -1;
            bottom: -13vw;
            width: 6.5vw;
            height: 26vw;
            background-image: url("../../../../assets/images/vectors/vector13.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .attendee-overview {
        position: relative;
        z-index: 0;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url("../../../../assets/images/backgrounds/gridBg.png");
        &::before {
            position: absolute;
            display: block;
            content: " ";
            right: 0;
            z-index: -1;
            top: -13vw;
            width: 6.5vw;
            height: 26vw;
            background-image: url("../../../../assets/images/vectors/vector13.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        &::after {
            position: absolute;
            display: block;
            content: " ";
            left: 0;
            bottom: -10.5vw;
            z-index: -1;
            width: 9vw;
            height: 18vw;
            background-image: url("../../../../assets/images/vectors/vector10.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        > .p-container {
            position: relative;
            &::before {
                position: absolute;
                display: block;
                content: " ";
                left: 0;
                top: 3%;
                z-index: 0;
                width: 22vw;
                height: 22vw;
                background-image: url("../../../../assets/images/vectors/vector14.svg");
                background-size: contain;
                background-repeat: no-repeat;
                transform: rotate(-15deg);
                @include media("maxOldLapTop") {
                    display: none;
                }
            }
        }
        .arrowHead {
            position: absolute;
            top: 50%;
            &::after {
                position: absolute;
                display: block;
                content: " ";
                left: 0vw;
                bottom: 80%;
                z-index: -1;
                width: 11vw;
                height: 11vw;
                background-image: url("../../../../assets/images/vectors/vector15.svg");
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    .course-overview {
        background-color: var(--primary);
        .section-header {
            &--content {
                h2 {
                    color: var(--light-clr);
                }
                p {
                    color: var(--light-clr);
                    font-weight: 300;
                }
            }
        }
        .showMoreItems {
            a {
                color: var(--light-clr);
                border-color: var(--light-clr);
            }
        }
    }
    .container-overview {
        background-color: var(--light-clr);
    }
}
