@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-family: var(--font-family-button);
    padding: 0 20px !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    &.btn-secondary {
        color: var(--secondary-button-text-color) !important;
        background-color: var(--secondary-button-background-color) !important;
        border: var(--secondary-button-border-width) solid var(--secondary-button-border-color) !important;
        border-radius: var(--secondary-button-border-radius) !important;
        box-shadow: 0 3px 9px var(--secondary-button-box-shadow-color) !important;
        &:hover {
            color: var(--secondary-button-text-color--hover) !important;
            background-color: var(--secondary-button-background-color--hover) !important;
            border: var(--secondary-button-border-width) solid var(--secondary-button-border-color--hover) !important;
            box-shadow: 0 3px 9px var(--secondary-button-box-shadow-color);
        }
        &.active {
            color: var(--secondary-button-text-color--active) !important;
            background-color: var(--secondary-button-background-color--active) !important;
            border: var(--secondary-button-border-width) solid var(--secondary-button-border-color--active) !important;
            box-shadow: 0 3px 9px var(--secondary-button-box-shadow-color);
        }
    }

    &.btn-primary {
        color: var(--primary-button-text-color) !important;
        background-color: var(--primary-button-background-color) !important;
        border: var(--primary-button-border-width) solid var(--primary-button-border-color) !important;
        border-radius: var(--primary-button-border-radius) !important;
        box-shadow: 0 3px 9px var(--primary-button-box-shadow-color) !important;
        &:hover {
            color: var(--primary-button-text-color--hover) !important;
            background-color: var(--primary-button-background-color--hover) !important;
            border: var(--primary-button-border-width) solid var(--primary-button-border-color--hover) !important;
        }
        &.active {
            color: var(--primary-button-text-color--active) !important;
            background-color: var(--primary-button-background-color--active) !important;
            border: var(--primary-button-border-width) solid var(--primary-button-border-color--active) !important;
        }
    }
    &.btn-danger {
        color: var(--danger-button-text-color) !important;
        background-color: var(--danger-button-background-color) !important;
        border: var(--danger-button-border-width) solid var(--danger-button-border-color) !important;
        border-radius: var(--danger-button-border-radius) !important;
        box-shadow: 0 3px 9px var(--danger-button-box-shadow-color) !important;
        &:hover {
            color: var(--danger-button-text-color--hover) !important;
            background-color: var(--danger-button-background-color--hover) !important;
            border: var(--danger-button-border-width) solid var(--danger-button-border-color--hover) !important;
        }
        &.active {
            color: var(--danger-button-text-color--active) !important;
            background-color: var(--danger-button-background-color--active) !important;
            border: var(--danger-button-border-width) solid var(--danger-button-border-color--active) !important;
        }
    }
}
