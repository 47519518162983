@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.users-list {
    &--inner {
        .title {
            h4 {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                i {
                    padding-bottom: 3px;
                    padding-right: 5px;
                    font-size: 1.1rem;
                }
                span {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    word-break: break-word;
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: var(--card-text-color);
                }
            }
        }
        .content {
        }
        .showMore {
            padding-left: 55px;
            a {
                font-size: 0.8rem;
                font-weight: 700;
                line-height: normal;
                color: var(--primary);
                text-decoration: none;
            }
        }
    }
}
.user-item {
    &--inner {
        display: flex;
        align-items: center;
        min-height: 55px;
        a {
            display: flex;
            width: 100%;
            margin: auto 0;
            .profile {
                display: flex;
                align-items: center;
                padding-right: 7px;
                i {
                    display: block;
                    width: 36px;
                    height: 36px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 50%;
                }
            }
            .details {
                display: grid;
                width: 100%;
                margin: auto 0;
                h4 {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1px;
                    overflow: hidden;
                    font-size: 0.9rem;
                    font-weight: 500;
                    color: var(--card-text-color);
                    p {
                        display: -webkit-box;
                        margin-bottom: 0;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }
                }
                .job-company {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    font-size: 0.7rem;
                    font-weight: 600;
                    color: var(--card-second-text-color);
                    line-height: normal;
                }
            }
        }
    }
}
