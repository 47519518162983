@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

body.modal-open #root {
    opacity: 0.8;
}
.modal-backdrop.show {
    background-color: var(--modal-backdrop-background-color) !important;
    opacity: 0.8 !important;
}

.content-modal-dialog {
    overflow-x: hidden;

    .modal-dialog {
        display: flex;
        height: 90%;

        &.modal-sm {
            max-width: 500px !important;
            @include media("mobile") {
                max-width: 90% !important;
                height: auto !important;
                margin: auto;
                margin-top: 5%;
                margin-bottom: 5%;
            }
        }
    }

    .modal-content {
        justify-content: center !important;
        padding: 35px;
        margin: auto;
        background-color: var(--modal-background-color);
        border: none;
        border-radius: var(--modal-border-radius) !important;
        @include media("mcbk-1") {
            padding: 20px;
        }
        @include media("mcbk-2") {
            padding: 20px;
        }
        @include media("mobile") {
            padding: 10px;
        }
    }

    .modal-header {
        padding: 0rem 2rem 1rem 0rem !important;
        justify-content: left !important;
        background-color: var(--modal-background-color);
        border-bottom: unset !important;
        border-top-left-radius: var(--modal-border-radius) !important;
        border-top-right-radius: var(--modal-border-radius) !important;
        h4 {
            display: flex;
            word-break: break-word;
            font-size: 1.3rem;
            color: var(--modal-text-color);
        }

        .close {
            position: absolute;
            top: 24px;
            right: 15px;
            padding: 0 !important;
            margin: auto 5px !important;
            font-size: 35px;
            font-weight: 300;
            color: var(--primary);
            opacity: 0 !important;
            height: 20px !important;
            overflow: hidden;

            &:hover {
                color: var(--primary);
            }
        }
        .close-icon {
            position: absolute;
            right: 22px;
            top: 16px;
            font-size: 26px;
            font-weight: 300;
            color: var(--primary);
            opacity: 1 !important;
        }
    }

    .modal-body {
        padding: 1rem 0rem 0rem 0rem !important;
        font-size: 16px;
        font-weight: 400;
        color: var(--card-second-text-color);
        text-align: left;
        overflow-wrap: break-word;
        @include media("mobile") {
            font-size: 13px;
        }
        p {
            text-align: justify;
            margin: 0px !important;
        }
        .modal-conference-tags {
            display: inline-grid;
            align-content: center;
            height: 26px;
            padding: 0 15px;
            padding-top: 3px;
            margin: 3px;
            font-size: 12px;
            font-weight: 700;
            color: var(--primary);
            user-select: none;
            background-color: var(--primary-10);
            border: 1px solid var(--primary);
            border-radius: var(--tag-border-radius);
        }
    }
}
