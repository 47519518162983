@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.course-overview {
    background-color: var(--default-overview-background-color);
    .p-container {
        padding: 10px;
    }
    &--items {
        padding: 20px 0;
        @include media("xll") {
            padding: 20px 20px;
        }
    }
}
