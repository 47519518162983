@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.request-card {
    background-image: url("../images/requestBackground.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &--content {
        > div {
            @include media("mobile") {
                flex-wrap: wrap;
            }
            .label {
                width: 450px;
                padding-top: 65px;
                padding-bottom: 60px;
                @include media("mobile") {
                    width: auto;
                    padding-top: 25px;
                    padding-bottom: 5px;
                    h3 {
                        font-size: 1.5rem !important;
                    }
                }
                h3 {
                    font-size: 1.8rem;
                    color: var(--light-clr);
                }
            }
            .actions {
                padding-top: 60px;
                padding-bottom: 60px;
                @include media("mobile") {
                    padding-top: 5px;
                    padding-bottom: 25px;
                    margin-left: 0 !important;
                }
                .registerBtn {
                    height: 50px;
                    padding: 0 50px !important;
                    font-size: 1rem !important;
                    border-radius: 3px !important;
                    i {
                        font-size: 0.7rem;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}
