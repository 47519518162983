@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";

.section-header {
    &--content {
        width: 40vw;
        margin: auto;
        padding: 10px;
        text-align: center;
        @include media("xl") {
            width: 90vw;
        }
        h2 {
            font-size: 2.2rem;
            font-weight: 700;
            color: var(--section-header-title-color);
        }
        p {
            font-size: 1rem;
            font-weight: 400;
            color: var(--section-header-subtitle-color);
        }
    }
}
