@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/screen-sizes";
:root {
    --video-category-background-color: var(--primary);
}
.video-item {
    min-height: 300px;
    margin-bottom: 30px;
    &--card {
        cursor: pointer;
        .banner {
            &--content {
                position: relative;
                overflow: hidden;
                content: "";
                aspect-ratio: 16/9;
                border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                .top-ribbon {
                    position: absolute;
                    top: 0px;
                    left: -40px;
                    transform: rotate(-43deg);
                    .content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 140px;
                        height: 28px;
                        padding: 12px 6px 10px 6px;
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--light-clr);
                        background-color: var(--danger);
                        box-shadow: 0px 3px 9px #9ba7ba;

                        &:hover {
                            text-decoration: none;
                        }
                        span {
                            display: -webkit-box;
                            overflow: hidden;
                            width: 50px;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }
                        i {
                            margin-right: 2px;
                            margin-bottom: 2px;
                            font-size: 18px;
                        }
                    }
                }
                .closeBtn {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    width: 30px;
                    height: 30px;
                    padding: 0 !important;
                    i {
                        font-size: 1.2rem;
                        font-weight: 500;
                    }
                }
                .muteBtn {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    z-index: 2;
                    width: 30px;
                    height: 30px;
                    padding: 0 !important;
                    border-radius: var(--circle-border-radius) !important;
                    background-color: var(--secondary-60) !important;

                    i {
                        font-size: 18px;
                    }
                }
            }

            .category {
                position: absolute;
                top: 69px;
                left: -9px;
                .content {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    height: 28px;
                    padding: 0 10px;
                    background-color: var(--video-category-background-color);
                    border-radius: 3px;
                    span {
                        display: -webkit-box;
                        min-width: fit-content;
                        max-width: inherit;
                        overflow: hidden;
                        padding-top: 2px;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        font-size: 0.8rem;
                        font-weight: 700;
                        color: var(--light-clr);
                        word-break: break-all;
                    }
                    &:after {
                        content: "\e04a";
                        font-family: "Font Awesome Kit";
                        transform: rotate(180deg);
                        font-size: 10px;
                        position: absolute;
                        left: 0.5px;
                        top: -7.5px;
                        color: var(--video-category-background-color);
                    }
                }
            }
        }
        .context {
            z-index: 0;
            margin-top: -20px;
            margin-left: 20px;
            margin-right: 20px;
            &--inner {
                padding: 10px;
                background-color: var(--card-background-color);
                border-radius: var(--card-border-radius);
                .header {
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                    overflow-wrap: anywhere;
                    height: 50px;
                    .info {
                        margin-top: 5px;
                        line-height: 0.8rem;
                        span {
                            font-size: 0.8rem;
                            font-weight: 900;
                            color: var(--card-text-color);
                            &.time {
                                padding-left: 5px;
                                .dot {
                                    font-size: 0.7rem;
                                }
                            }
                        }
                    }

                    .actions {
                        display: flex;
                        align-items: center;
                        margin-right: 0;
                        margin-left: auto;
                        padding-left: 5px;
                        .playBtn {
                            display: flex;
                            align-items: center;
                            margin-right: 8px;
                            i {
                                font-size: 1.8rem;
                            }
                        }
                        .foldBtn {
                            width: 28px;
                            height: 28px;
                            padding: 0 !important;
                            margin-right: 8px;
                            i {
                                font-weight: 500;
                            }
                        }
                        .shareBtn {
                            width: 28px;
                            height: 28px;
                            padding: 0 !important;
                            i {
                                font-weight: 500;
                            }
                        }
                    }
                }
                .title {
                    height: 45px;
                    h3 {
                        display: -webkit-box;
                        width: fit-content;
                        font-size: 1.1rem;
                        font-weight: 700;
                        color: var(--card-text-color);
                        margin-bottom: 0;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        cursor: pointer;
                    }
                }
            }
        }
        &:hover {
            transition: 0.5s;
            transform: scale(1.05);
            z-index: 5;
            position: absolute;
            left: auto;
            right: 0;
            .context {
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 5px;
            }
            @include media("mobile") {
                transform: scale(1);
                position: relative;
            }
        }
    }
    &.popup {
        margin-bottom: 0;
        .video-item--card {
            .context {
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 10px;
                &--inner {
                    .header {
                        .category {
                            margin-top: 4px;
                            .content {
                                background-color: unset !important;
                                span {
                                    max-width: 100% !important;
                                }
                            }
                        }
                        .info {
                            margin-right: 0;
                            margin-left: auto;
                            margin-top: 7px;
                        }
                        .actions {
                            margin-right: unset;
                            margin-left: unset;
                        }
                    }
                }
            }
            &:hover {
                transform: none;
            }
        }
    }

    @include media("ecg-1") {
        max-width: 100% !important;
        flex: 100% !important;
    }
    @include media("ecg-2") {
        max-width: 33.33% !important;
        flex: 33.33% !important;
    }
}
.video-detail-popup {
    .modal-dialog {
        height: auto;
        .modal-content {
            padding: 0;
            .modal-body {
                padding-top: 0 !important;
                .context {
                    &--inner {
                        padding: 0;
                        margin-bottom: 20px;
                        .header {
                            .category {
                                .content {
                                    span {
                                        position: relative;
                                        display: -webkit-box;
                                        width: fit-content;
                                        word-break: break-all;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        overflow: hidden;
                                        height: 20px;
                                        max-width: 100px;
                                        padding: 1px 10px 0;
                                        font-size: 0.7rem;
                                        line-height: 20px;
                                        margin-right: 10px;
                                        font-weight: 700;
                                        color: var(--light-clr);
                                        background-color: var(--video-category-background-color);
                                        border-radius: 3px;
                                    }
                                }
                            }
                        }
                        .title {
                            height: auto;
                            h3 {
                                margin-bottom: 10px;
                                cursor: default;
                            }
                        }
                        .desc {
                            margin-bottom: 10px;
                            .text {
                                margin-bottom: 0 !important;
                                font-size: 0.9rem;
                                font-weight: 400;
                                color: var(--card-text-color);
                            }
                            .showMore {
                                padding-top: 5px;
                                font-size: 0.8rem;
                                font-weight: 700;
                                line-height: normal;
                                color: var(--primary);
                                text-decoration: none;
                            }
                        }
                        .tags {
                            margin-bottom: 10px;
                            .title {
                                h4 {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 0;
                                    i {
                                        padding-bottom: 3px;
                                        padding-right: 5px;
                                        font-size: 1.1rem;
                                    }
                                    span {
                                        display: -webkit-box;
                                        overflow: hidden;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        word-break: break-word;
                                        font-size: 0.9rem;
                                        font-weight: 700;
                                        color: var(--card-text-color);
                                    }
                                }
                            }
                            .content {
                                .item {
                                    span {
                                        display: -webkit-box;
                                        width: fit-content;
                                        height: 20px;
                                        overflow: hidden;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        align-content: center;
                                        padding: 0 15px;
                                        margin-right: 11px;
                                        font-size: 0.8rem;
                                        line-height: 20px;
                                        font-weight: 700;
                                        color: var(--primary);
                                        -webkit-user-select: none;
                                        -ms-user-select: none;
                                        user-select: none;
                                        background-color: var(--tag-background-clr);
                                        border-radius: var(--tag-border-radius);
                                    }
                                }
                            }
                            .showMore {
                                padding-top: 5px;
                                font-size: 0.8rem;
                                font-weight: 700;
                                line-height: normal;
                                color: var(--primary);
                                text-decoration: none;
                            }
                        }
                    }
                    .video-card-border {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 8px;
                        background-color: #f2994a;
                        z-index: 2;
                        border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
                    }
                }
                .users-list {
                    &--inner {
                        .title {
                            height: auto;
                        }
                    }
                    &.scrollable {
                        .content {
                            max-height: 170px;
                            overflow: auto;
                            display: flex;
                            flex-wrap: wrap;
                            scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-background);
                            scrollbar-width: auto;
                            &::-webkit-scrollbar {
                                width: 5px;
                            }
                            &::-webkit-scrollbar-track {
                                background: var(--scrollbar-background);
                            }

                            &::-webkit-scrollbar-thumb {
                                background: var(--scrollbar-widget-thumb);
                            }

                            &::-webkit-scrollbar-thumb:hover {
                                opacity: 100%;
                            }
                            .user-item {
                                flex: 50%;
                                max-width: 50%;
                                padding-right: 10px;
                                .job-company {
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.video-gallery-full-window {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999;
    background-color: black;

    .qumu-gallery {
        height: 100vh !important;
    }

    .close-button {
        transition: opacity ease-in-out 0.2s;
        opacity: 0.9;
        position: fixed;
        top: 15px;
        right: 15px;
        width: 32px;
        height: 32px;
        padding: 0 !important;
        z-index: 1000000;
        i {
            font-size: 18px;
        }
    }
}
