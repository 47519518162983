@import "../../../../assets/scss/variables";

//text tooltip
.tooltip {
    opacity: 1 !important;
    &.show {
        z-index: 10000 !important;
    }
    .arrow {
        &:before {
            top: -8px !important;
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            border: none !important;
            background-color: white !important;
            box-shadow: 3px 7px 9px rgba(155, 167, 186, 0.25);
        }
    }
    .tooltip-inner {
        padding: 10px;
        color: var(--card-text-color);
        background-color: white !important;
        box-shadow: 0px 3px 9px rgba(155, 167, 186, 0.25);
        font-size: 12px;
        font-weight: 700;
        font-family: var(--font-family-body);
    }
    &.bs-tooltip-bottom {
        .arrow {
            top: 10px;
            z-index: 0;
            &::before {
                box-shadow: -4px -5px 9px rgba(155, 167, 186, 0.25);
            }
        }
    }
}
//end--text tooltip

.app-text-hover-tooltip {
    align-self: center;
}
