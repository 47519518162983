$media-queries: (
    "mini": "only screen and (max-width: 320px)",
    "xs": "only screen and (max-width: 375px)",
    "sm": "only screen and (max-width: 576px)",
    "mobile": "only screen and (max-width: 768px)",
    "lg": "only screen and (max-width: 992px)",
    "xl": "only screen and (max-width: 1200px)",
    "xll": "only screen and (max-width: 1500px)",
    "x3l": "only screen and (max-width: 1700px)",
    "x4l": "only screen and (max-width: 1920px)",
    "minOldLapTop": "only screen and (min-width: 1024px)",
    "maxOldLapTop": "only screen and (max-width: 1024px)",
    //header
    "expandToggleMenu": "only screen and (max-width: 1199.9px)",
    "apphdr-1": "(min-width: 1200px)and (max-width: 1499px)",
    //App Container
    "pc-1": "(min-width: 1500px) and (max-width: 1700px)",
    "pc-2": "(min-width: 1701px)",
    //event card grid
    "ecg-1": "(min-width: 576px) and (max-width: 687px)",
    "ecg-2": "(min-width: 1200px) and (max-width: 1499px)",
    //event card hovering
    "ech-1": "(max-width: 992px)",
    "ech-2": "(min-width: 992px)and (max-width: 1498px)",
    "ech-3": "(min-width: 1499px)",
    //attendee card
    "atc-1": "(max-width: 1400px) and (min-width: 1200px)",
    //footer
    "fgc-1": "(min-width: 1500px)",
    "fgc-2": "(min-width: 1200px) and (max-width: 1500px)",
    "fgc-3": "(min-width: 1024px) and (max-width: 1200px)",
    "fgc-4": "(min-width: 768px) and (max-width: 1024px)",
    "fgc-5": "(max-width: 768px)",
    "fgc-6": "(max-width: 1500px)",
    //custom for default screen viewport size
    "mcbk-1": "only screen and (min-width: 1200px) and (max-width: 1379px)",
    "mcbk-2": "only screen and (min-width: 1380px) and (max-width: 1700px)",
    //banner
    "apbnr-1": "only screen and (max-width: 1350px)",
);

@mixin media($breakpoints) {
    $conditions: ();

    @each $breakpoint in $breakpoints {
        $conditions: append($conditions, #{map-get($media-queries, $breakpoint)}, comma);
    }

    @media #{$conditions} {
        @content;
    }
}
